import React from 'react';
import { Layout } from '../components/Layout';
import { CommonHeader } from '../components/CommonHeader';
import styled from 'styled-components';

import icon_natural_product from '../assets/presentation/icons/natural_product.png';
import icon_eco_friendly from '../assets/presentation/icons/eco-friendly.png';
import icon_un_sdg from '../assets/presentation/icons/un_sdg.png';
import icon_un_fao from '../assets/presentation/icons/un_fao.png';
import icon_carbon_footprint from '../assets/presentation/icons/carbon_footprint.png';
import icon_all from '../assets/presentation/icons/icon_all.png';

import small_calf from '../assets/animal/small_calf.png';
import ammonia from '../assets/animal/ammonia.png';
import bacteria from '../assets/animal/bacteria.png';
import fly from '../assets/animal/fly.png';
import sustainable from '../assets/animal/sustainable.png';
import doctor from '../assets/animal/doctor.png';
import goat_eating from '../assets/animal/goat_eating.png';
import cow_growth from '../assets/animal/cow_growth.png';
import chicken_shield from '../assets/animal/chicken_shield.png';
import anticaking_graph from '../assets/animal/anticaking_graph.png';
import assembly_line from '../assets/animal/assembly_line.png';
import pellet_power from '../assets/animal/pellet_power.png';
import aquaponics from '../assets/animal/aquaponics.png';
import algae from '../assets/animal/algae.png';
import filteration from '../assets/animal/filteration.png';
import healthy_fish from '../assets/animal/healthy_fish.png';
import bacteria_fish from '../assets/animal/bacteria_fish.png';
import water_barrier from '../assets/animal/water_barrier.png';
import flowability from '../assets/animal/flowability.png';
import { Helmet } from 'react-helmet';

const Styles = styled.div`

    .headerstyle {
        text-align: center;
        font-family: Neutraface Display,script;
        padding-top: 20px;
        text-transform: uppercase;    
        font-size: 2.5em;
    }

    .subheaderstyle {
        text-align: center;
        font-family: Neutraface Display,script;
        padding-top: 20px;
        font-size: 2.2em;
    }
    .tableheaderstyle {
        text-align: center;
        font-family: Neutraface Display,script;
        padding-top: 20px;
        font-size: 1.8em;
    }

    .ludwig {
        font-size: 1.5em;
        width:100%;
        font-family:Open Sans;
        font-style: bold;
        color: #555555;
        padding:0.5em 30px 1.2em 75px;
        line-height:1.6;
        position: relative;
    }

    .processflow {
        width: 100%;
        padding-bottom: 30px;
    }

    .cimprogettilogo {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .shaftcol {
        float: left;
        width: 50%;
        padding: 20px 150px 0px 150px;
    }

    .shaftrow {
        content: "";
        clear: both;
        display: table;
    }

    .cardtext {
        color: black;
        font-size: 1.2em;
    }

    .imagestyle {
        width: 70%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .rowText-left{
        font-size: 1.8em;
        font-style: bold;
        color: black;
        font-family: Open Sans;
        text-align: center;
        vertical-align: middle;
        border-right: 1.2px solid black;
    }
    .rowText-right{
        font-size: 1.2em;
        font-style: bold;
        color: black;
        font-family: Open Sans;
    }
    .tableStyle {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        border: 3px solid black;
    }
    .rowFormat {
        border-bottom: 1.2px solid black;
    }
    .mycon {
        display: flex;
        flex-wrap: wrap;
    }
    .mybox {
        flex: 1;
        flex-basis: 33.33%;
        text-align: center;
        padding: 10px;
    }
    .plant_bg {
        // background-color: rgb(11,114,97);
        background-color: rgb(11,100,97);
        margin: 0px;
        padding: 0px;
    }
    .quote {
        font-style:italic;
        color: #666;
        font-size: 1.5em;
        padding: 10px 20px;
        border-left: 5px solid #ddd;
        margin: 20px 0;
        ${'' /* background-color: rgb(255,247,228); */}
    }

    .highlight {
        height: 12px;
        width: 50%;
        max-width: 90vw;
        line-height: 1.6;
        border-radius: 2px;
        background-color: #2e2459;
        // margin: 0 auto;
    }
    .highlight2 {
        height: 12px;
        width: 50%;
        max-width: 90vw;
        line-height: 1.6;
        border-radius: 2px;
        background-color: #a72145;
        // margin: 0 auto;
    }
    @media only screen and (min-width: 768px) {
        .highlight {
            width: 20%;
        }
        .highlight2 {
            width: 20%;
        }
    }
`;

export const Animal = () => (
    
    <React.Fragment>
        <Helmet>
            <title>Comprehensive Animal Care Products at Mahameru</title>
            <meta name="description" content="The products that take care of your animals and their well-being are M-TerraDry, M-Detox, M-Aquacare and M-Perform" />
        </Helmet>
    <CommonHeader 
        title="Animal Welfare"
        para="Animal Care & Feed Additive"
    />
    <Styles>
        <Layout>
            {/* TODO: can try centering the below para  */}

            <div className='mycon' style={{paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='mybox'>
                <img src={icon_natural_product}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'center', fontSize: '1.75rem'}}>Natural Products</h3>
                    <p style={{textAlign: 'left'}}>All of our products are pure in nature without any chemical additives at any processing stage.</p>
                </div>
                <div className='mybox'>
                    <img src={icon_eco_friendly}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'center', color: 'black', fontSize: '1.75rem'}}>Eco-Friendly</h3>
                    <p style={{color: 'black',textAlign: 'left'}}>They are chemically inert and are not harmful to the Environment, Humans or Animals.</p>
                </div>
                <div className='mybox'>
                    <img src={icon_un_sdg}
                            width={250}
                            height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'center', color: 'black', fontSize: '1.75rem'}}>Commited to UN SDGs</h3>
                    <p style={{color: 'black',textAlign: 'left'}}>We are aligned with UN's Sustainable Development Goals: SDG 6, 11, 13, 15. 
                    We are focused on environmentally stable communities for enhanced quality of living.</p>
                </div>
            </div>
            <div className='mycon' style={{paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='mybox'>
                    <img src={icon_un_fao}
                            width={250}
                            height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'center', fontSize: '1.75rem'}}>Supporting Climate-Smart Agriculture (CSA)</h3>
                    <p style={{textAlign: 'left'}}>We take a unified approach to address climate challenges in relation to agriculture and food security.​</p>
                </div>
                <div className='mybox'>
                    <img src={icon_carbon_footprint}
                            width={250}
                            height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'center', color: 'black', fontSize: '1.75rem'}}>Dedicated to Reduction in Carbon Footprint</h3>
                    <p style={{color: 'black',textAlign: 'left'}}>Our solutions help significant reduction in the Carbon Footprint impact of urbanisation and development</p>
                </div>
                <div className='mybox'>
                    <img src={icon_all}
                            width={250}
                            height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'center', color: 'black', fontSize: '1.75rem'}}>Solutions that Align with your Needs</h3>
                    <p style={{color: 'black',textAlign: 'left'}}>Our support is not limited to supplying the products, we work on problems on the ground and leverage a wide base of research and knowledge from multiple fields
                     of Engineering, Agriculture, Biology and find the right approach as per local conditions to get results.</p>
                </div>
            </div>
            </Layout>

{/* ---------------------- START OF PRODUCTS --------------------------------- */}

            <div className='plant_bg'>
            <Layout>
            <h2 className="subheaderstyle" style={{color: 'white', fontWeight: 'bold', paddingBottom: '20px'}}>
                Animal Care & Wellbeing
            </h2><hr />
            <br></br><br></br>
            <h3 style={{textAlign: 'left', color: 'white', fontWeight: 'normal', fontSize: '1.9rem', paddingBottom: '15px', paddingTop: '20px'}}>
                M-TerraDry</h3>
                <div className='highlight'></div>
                <br></br>
                {/* <p style={{color: 'white', textAlign: 'center', fontSize: '1.5rem'}}>
                    Sustainable Bedding Conditioner for Stud, Bird, Dairy & All Other Animal Species</p> */}
                    <p className="quote" style={{textAlign: 'center', color: 'white'}}>
                    Sustainable Bedding Conditioner for Stud, Bird, Dairy & All Other Animal Species</p>

            <div className='mycon'>
                <div className='mybox'>
                    <img src={ammonia}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>High Ammonia Absorption and Volatile Compounds</h3>
                    <p style={{color: 'white'}}>
                    Increased productivity, lower pungent odours and lower ventilation costs. Reduction in pneumonia incidence and associated cost to company.
                    </p>
                </div>
                <div className='mybox'>
                <img src={bacteria}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Helps Control Bacteria, Virus & Fungi</h3>
                    <p style={{color: 'white'}}>
                    By acting as a drying agent & reducing the stress on animals, it helps in lowering the cost of medication, treatment & overall maintenance of the animals.
                    </p>
                </div>
                <div className='mybox'>
                <img src={fly}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Flies and Larvae Management</h3>
                    <p style={{color: 'white'}}>
                    Helps to create unfavorable conditions for flies and their larvae to survive. Reduces the stress levels of the animal and increases productivity.
                    </p>
                </div>
                <div className='mybox'>
                <img src={doctor}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Reducing Digital Dermatitis and Mastitis</h3>
                    <p style={{color: 'white'}}>
                    Provides cushioned bedding along with clean and dry conditions for feet & underside of animals.
                    </p>
                </div>
                <div className='mybox'>
                <img src={small_calf}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Calf Hatching</h3>
                    <p style={{color: 'white'}}>
                    It can help dry the calf faster & conserve their heat, allowing them to increase colostrum intake.
                    </p>
                </div>
                <div className='mybox'>
                <img src={sustainable}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Supports Sustainable Agriculture</h3>
                    <p style={{color: 'white'}}>
                    It supports plants as the absorbed nitrogen is available to plants and can help with water holding.
                    </p>
                </div>
            </div>
            <h3 style={{textAlign: 'left', color: 'white', fontWeight: 'normal', paddingTop:'20px', fontSize: '1.9rem', paddingBottom: '15px'}}>
                M-DeTox</h3>
                <div className='highlight'></div>
                <br></br>
                <p className="quote" style={{textAlign: 'center', color: 'white'}}>
                Toxin Binder & Anti-Caking for all Animals & Fish Feed <br></br>
                “Mycotoxins are toxic substances for humans and animals, developed by molds in foodstuffs. 
                The most important and most dangerous are aflatoxins.”</p>
            <div className='mycon'>
                <div className='mybox'>
                    <img src={goat_eating}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>High Mycotoxin Adsorption Upto 90%</h3>
                    <p style={{color: 'white'}}>
                    Sustainable reduction of aflatoxin and fumonisin levels in feed through strong and consistent adsorption thus inducing  
                    a lower amount of aflatoxin transformed in the liver and subsequently in milk or other animal products.
                    </p>
                </div>
                <div className='mybox'>
                <img src={cow_growth}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Enhanced Feed Conversion Efficiency</h3>
                    <p style={{color: 'white'}}>
                    Eco-friendly improvement in feed conversion efficiency by reducing feed loss in feces and increasing nutrient assimilation.
                    </p>
                </div>
                <div className='mybox'>
                <img src={chicken_shield}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Immune System Support</h3>
                    <p style={{color: 'white'}}>
                    Counters the immuno-suppressive effects of mycotoxins thus protecting the animals from gut related infections.
                    </p>
                </div>
            </div>
            <h3 style={{textAlign: 'left', color: 'white', fontWeight: 'normal', paddingTop:'20px', fontSize: '1.9rem', paddingBottom: '15px'}}>
                M-AquaCare</h3>
                <div className='highlight'></div>
                <br></br>
                <p className="quote" style={{textAlign: 'center', color: 'white'}}>
                Sustainable, Eco- & Fish-Friendly, Antibiotic Free, Natural water treatment</p>
            <div className='mycon'>
                <div className='mybox'>
                    <img src={bacteria_fish}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Bacterial Disease Prevention</h3>
                    <p style={{color: 'white'}}>
                    It is a natural preventative treatment for fish and reduces the bacterial attachment to fish tissues and scales for 
                    diseases such as columnaris & aeromonas. 
                    </p>
                </div>
                <div className='mybox'>
                <img src={algae}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Algae Bloom control</h3>
                    <p style={{color: 'white'}}>
                    Algae Bloom produces toxins and consumes oxygen that severely decreases fish survival & growth rates. 
                    M-AquaCare will fight this and keep the fish vats/tanks healthy. 
                    </p>
                </div>
                <div className='mybox'>
                <img src={filteration}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Easy Filtration</h3>
                    <p style={{color: 'white'}}>
                    M-AquaCare closes in on the suspended organic matter & other bacteria forming small clumps that are 
                    easy to remove with existing filters in re-circulation systems. 
                    </p>
                </div>
                <div className='mybox'>
                <img src={healthy_fish}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Long term effect</h3>
                    <p style={{color: 'white'}}>
                    The bacteria it has attached to will die and have no lasting effect. 
                    It is classified as unregulated silt/dirt (since it a clay product) after use and can be placed anywhere after removal from tanks. 
                    </p>
                </div>
                <div className='mybox'>
                <img src={aquaponics}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Aquaponics Use</h3>
                    <p style={{color: 'white'}}>
                    It is an ideal product for maintaining the health of the fish in Aquaponics as it is a 
                    natural clay product so it will not affect the plants and will keep the fish healthy at the same time. 
                    </p>
                </div>
            </div>
            </Layout>
            </div>
            <div style={{backgroundColor: 'rgb(56, 46, 99)', margin: '0px', padding: '0px'}}>
                <Layout>
                    <h2 className="subheaderstyle" style={{color: 'white', fontWeight:'bold', paddingBottom: '20px'}}>Animal Feed Mills</h2><hr />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'left', color: 'white', fontWeight: 'normal', paddingTop:'20px', fontSize: '1.9rem'}}>
                M-Perform</h3>​
                <div className='highlight2'></div>
                <br></br>
                <p className="quote" style={{textAlign: 'center', color: 'white'}}>
                Feed Mill Additive for Anti-Caking, Lubricant, Mix Homogeneity Effect</p>
            <div className='mycon'>
                <div className='mybox'>
                    <img src={assembly_line}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Zero Abrasion</h3>
                    <p style={{color: 'white'}}>
                    With a hardness of 1 on Mohr’s scale, it ensures extremely Low wear & Tear on equipment thus reducing long-term 
                    maintenance costs and improving the carbon footprint of the company.
                    </p>
                </div>
                <div className='mybox'>
                <img src={anticaking_graph}
                   width={320}
                   height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Anti-Caking Power</h3>
                    <p style={{color: 'white'}}>
                    M-Perform is an excellent anticaking, lubricant and mix homogenizer.
                    </p>
                </div>
                <div className='mybox'>
                <img src={pellet_power}
                    width={360}
                    height={240}
                    style={{marginLeft: '-10px'}}
                />
                    <br></br><br></br>
                    {/* TODO: Change */}
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Increases Pelletizing Speed & Power</h3>
                    <p style={{color: 'white'}}>
                    During pelleting, for a fixed power consumption, the addition of M-Perform increases through-put up to 25%
                    </p>
                </div>
                <div className='mybox'>
                <img src={water_barrier}
                   width={360}
                   height={240}
                   style={{marginLeft: '-10px'}}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Water Pick-up Barrier</h3>
                    <p style={{color: 'white'}}>
                    Upto 4% inclusion rate ensures a 1.3 times reduction in water pickup as compared to no treatment.
                    </p>
                </div>
                <div className='mybox'>
                <img src={flowability}
                   width={360}
                   height={240}
                   style={{marginLeft: '-10px'}}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Flowability Effect</h3>
                    <p style={{color: 'white'}}>
                    As the addition rate increases the angle required to make soya meal flow is reduced.
                    </p>
                </div>
            </div>
                </Layout>
            </div>
    </Styles>
</React.Fragment>
)