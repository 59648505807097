import React from 'react';
import { Layout } from '../components/Layout';
//import { Table } from 'react-bootstrap';
import { CommonHeader } from '../components/CommonHeader';
import xrfmachine from '../assets/xrfmachine.png';
import machine1 from '../assets/machine1.png';
import machine2 from '../assets/machine2.png';
import networkDesign from '../assets/Network.png';
import weighbridge from '../assets/weighbridge.jpg';
import facialrecog from '../assets/facerecog.jpg';
import styled from 'styled-components';
import icon_link from '../assets/software/icon_link.png';
import icon_pattern from '../assets/software/icon_pattern.png';
import icon_custom from '../assets/software/icon_custom.png';
import graphdb from '../assets/software/graphdb.png';

const Styles = styled.div`

    .headerstyle2 {
        text-align: center;
        font-family: Neutraface Display, script;
        padding-bottom: 20px;
        padding-top: 30px;
        ${'' /* text-transform: uppercase; */}
        font-size: 2.5em;
    }

    .productheader {
        text-align: center;
        font-family: Neutraface Display, script;
        padding-bottom: 20px;
        padding-top: 30px;
        text-transform: uppercase;
    }

    .imagestyle {
        width: 70%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .quicklimepic {
        width: 60%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 25px;
        padding-top: 25px;
    }

    .ludwig {
        font-size: 1.5em;
        width: 100%;
        font-family: Open Sans;
        font-style: bold;
        color: #555555;
        padding-bottom: 25px;
        
    }

    .tableStyle {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        
    }

    .rowText{
        font-size: 1.2em;
        font-style: bold;
        color: black;
        font-family: Open Sans;
    }
    .mycon {
        display: flex;
        flex-wrap: wrap;
        ${'' /* align-items: center; */}
    }
    .mybox {
        flex: 1;
        flex-basis: 33.33%;
        text-align: center;
        padding: 10px;
    }
    .quote {
        font-style:italic;
        color: #666;
        font-size: 1.5em;
        padding: 10px 20px;
        border-left: 5px solid #ddd;
        margin: 20px 0;
        ${'' /* background-color: rgb(255,247,228); */}
    }
    @media screen and (min-width: 600px) {
        .myimg {
            width: 50%;
        }
    }
    @media screen and (max-width: 600px) {
        .myimg {
            width: 100%;
        }
    }

`;


export const Software = () => (
    <React.Fragment>
    <CommonHeader 
        title="Software Development & IT"
        para="Only The Highest Quality Standards"
    />
    <Styles>
        <Layout>
        <p className="quote" style={{textAlign: 'center'}}>
                "Software as a general tool is part of our everyday lives, but Software that fits like a glove designed to enhance the user can go beyond the ordinary."
            </p>
            {/* <h1 className='headerstyle'>Our Software Solutions<hr /></h1>
            <p className='ludwig'>
            We offer customized Cloud Based Solutions that integrates with any ERP such as Tally, for data management and industrial equipment such as Weigh Bridge, Lab Testing Machines, attendance machines etc. We also develop customized IoT solutions integrated with machine learning to enable effective data analysis.
            </p> */}
            <h1 className='headerstyle2'>M-Unify (Data Unification Tool)<hr /></h1>
            <div className='mycon' style={{paddingBottom: '50px', alignItems:'center'}}>
                <img src={graphdb} className='myimg' />
                <div className='mybox'>
                    <p className='ludwig'>
                    M-Unify is a data tool that has the ability to unify the various data streams across the business. 
                    It can offer synchronization between the various units within the company at a level never before possible and can greatly enhance the productivity. 
                    It relies on mathematics and simple relations between data points to understand the business as a whole and create an actionable path towards the company’s core competencies and aims.
                    </p>
                </div>
            </div>
            <div className='mycon'>
                <div className='mybox'>
                <img src={icon_link}
                        width={100}
                        height={100}
                    />
                    <h3 style={{textAlign: 'left'}}>Unified Data Structure</h3>
                    <p style={{textAlign: 'left'}}>M-Unify synchronizes your data across different processes and departments creating a depth of knowledge within the company that
                    builds over the years.</p>
                </div>
                <div className='mybox'>
                <img src={icon_pattern}
                        width={100}
                        height={100}
                    />
                    <h3 style={{textAlign: 'left', color: 'black'}}>Data Correlation</h3>
                    <p style={{color: 'black',textAlign: 'left'}}>M-Unify acts on the relationships between the data and recognises patterns. This enables using historic data
                    to empower your decisions today.</p>
                </div>
                <div className='mybox'>
                <img src={icon_custom}
                        width={100}
                        height={100}
                    />
                    <h3 style={{textAlign: 'left', color: 'black'}}>Fully Customisable</h3>
                    <p style={{color: 'black',textAlign: 'left'}}>M-Unify adapts to your business needs and guides the business towards data-driven decision making.</p>
                </div>
            </div>
            
            </Layout>
            <div style={{backgroundColor: 'rgb(11,114,97)', margin: '0px', paddingBottom: '100px'}}>
            <h1 className='headerstyle2' style={{color:'white'}}>WeighBridge Software<hr /></h1>
            <Layout>
            <p className="ludwig" style={{color:'white'}}>
            Our weigh bridge software eliminates the operator and enables autonomous entries into a ERP software. Our software is  AI enabled that allows for optimum management of sales and purchase orders as well as predictive data analysis system and an anti-cheating solution that uses AI tracking to monitor  the trucks. Our system blends seamlessly with the ERP in the backend and can be customized to interface with the team for data visualization.  
            </p>
            <img 
                src={weighbridge}
                alt="WeighBridge"
                className="imagestyle"
            />
            <h1 className='headerstyle2' style={{color:'white'}}>AI Attendance & Payroll Management System<hr /></h1>
            <p className="ludwig" style={{color:'white'}}>
            Our customized attendance and payroll management system comes with AI facial recognition software and geofencing. It has its own payroll system easing the work of the accountant as well as live tracking of employee attendance for the managers. It also has a shift management system for large industrial sites linked directly to the hierarchy of the company. It comes in the form of an app that can be installed on any phone and offers unparalleled flexibility.
            </p>
            <img 
                src={facialrecog}
                alt="Facial Recognition"
                className="imagestyle"
            />
            </Layout>
            </div>
            <div style={{backgroundColor: 'rgb(46, 36, 89)', margin: '0px', paddingBottom: '50px'}}>
            <Layout>
            <h1 className='headerstyle2' style={{color:'white'}}>Mineral Testing Sensor Integration<hr /></h1>
            <p className="ludwig" style={{color:'white'}}>
            For the limestone industry we have designed a software that integrates with the mineral testing machines to collect live data and feedback to the factory operator instantly. It comes with a machine learning package that can track multiple inputs and offer predictive analyses for optimum factory operation. Ultimately this software has the potential to automate factory operation with minimal human oversight.
            </p>
            
            <img 
                src={xrfmachine}
                alt="XRF Machine"
                className="imagestyle"
            />
            <img 
                src={machine1}
                alt="Machines"
                className="imagestyle"
            />
            <img 
                src={machine2}
                alt="Machines"
                className="imagestyle"
            />
            </Layout>
            </div>
            <Layout>
            <h1 className='headerstyle2' style={{color:'black'}}>Customised Software For Event Management<hr /></h1>
            <p className='ludwig' style={{color:'black'}}>
                Our Software Team works closely with our event management partners who require fast paced software and content updates. 
                We ensure that the event is executed with finese. While the event is ongoing we provide live data gathering and analysis that
                will put you ahead of the competition. We always provide our customers with detailed report and data analysis feedback that gives 
                our customers confidence to push the cieling with every event. The beauty of our solution lies in complete cloud integration
                requiring no on-site hardware and full remote management.
            </p>
            </Layout>
            <div style={{backgroundColor: 'rgb(167, 33, 69)', margin: '0px', paddingBottom: '50px'}}>\
            <Layout>
            <h1 className='headerstyle2' style={{color:'white'}}>IT Solution & Management<hr /></h1>
            <p className="ludwig" style={{color:'white'}}>
            We provide complete Cloud Based solution that can transform your business from being on-premise only to being cloud based allowing anywhere, anytime access. It involves operating the entire IT infrastructure backend over the cloud including all networking equipment cameras, server, workstations etc. This helps in reducing capital and operational costs dramatically. It also reduces the carbon footprint significantly. We provide 24/7 customer support.
            </p>
            <img 
                src={networkDesign}
                alt="Machines"
                className="imagestyle"
            />
            </Layout>
            </div>
        {/* </Layout> */}
    </Styles>
    </React.Fragment>
)