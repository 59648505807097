import React from 'react';
import { Layout } from '../components/Layout';
import { CommonHeader } from '../components/CommonHeader';
import { InfoCards } from '../components/InfoCards';
import styled from 'styled-components';
import farmbook from '../assets/farmbook.png';
import { SlideShow } from '../components/SlideShow';
import iotfarm from '../assets/farming/iotfarm.png';
import ballclay from '../assets/minerals/BallClay.jpg';
import bentonite from '../assets/minerals/bentonite.jpg';
import calciumCarbonate from '../assets/minerals/Calcium Carbonate.jpg';
import carbonBlack from '../assets/minerals/Carbon Black.jpg';
import diatomite from '../assets/minerals/diatomite.jpg';
import feldspar from '../assets/minerals/Feldspar.jpg';
import kaolin from '../assets/minerals/kaolin.jpg';
import mica from '../assets/minerals/Mica.jpg';
import moler from '../assets/minerals/Moler.jpg';
import perlite from '../assets/minerals/perlite.jpg';
import quartz from '../assets/minerals/Quartz.jpg';
import talc from '../assets/minerals/Talc.jpg';

const Styles = styled.div`

    .headerstyle {
        text-align: center;
        font-family: Neutraface Display,script;
        padding-top: 20px;
        text-transform: uppercase;    
        font-size: 2.5em;
    }

    .subheaderstyle {
        text-align: center;
        font-family: Neutraface Display,script;
        padding-top: 20px;
        font-size: 2em;
    }


    .ludwig {
        font-size: 1.5em;
        width:100%;
        font-family:Open Sans;
        font-style: bold;
        color: #555555;
        padding:0.5em 30px 1.2em 75px;
        line-height:1.6;
        position: relative;
    }

    .processflow {
        width: 100%;
        padding-bottom: 30px;
    }

    .cimprogettilogo {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .shaftcol {
        float: left;
        width: 50%;
        padding: 20px 150px 0px 150px;
    }

    .shaftrow {
        content: "";
        clear: both;
        display: table;
    }

    .cardtext {
        color: black;
        font-size: 1.2em;
    }

    .imagestyle {
        width: 70%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .cardMargin {
        margin-bottom: 80px;
        max-height: 394px;
    }
    .cardJustify {
        justify-content: space-evenly;
        padding: 5px 100px;
        width: 100%;
        margin: 0px;
    }

`;

export const Mineral = () => (
    
    <React.Fragment>
    <CommonHeader 
        title="Mineral Innovation"
        para="Innovative Approach For Improved Production"
    />
    <Styles>
        <Layout>

            <h1 className="headerstyle">The Mineral Revolution<hr /></h1>
            <p className="ludwig">
            The Hidden Backbone of every industry are the technological minerals that truly create our modern world products. 
            Industries like Ceramics, Cosmetics, Agriculture, Animal feed & Health, Steel making etc. all use a wide variety of Minerals as raw materials. 
            They are ever present in our day to day lives and impact us profoundly. <br/>The wide range of applications and industry requires a reliable and knowledgeable 
            support team. Our aim is to always provide tailor made solutions to our customers and always strive to tackle all the challenges of the industry, 
            so our customers can be at ease while we pave the way ahead for them.<br />
            <b>The minerals are as per EU regulations for Organic farming and are extensively used across Europe & USA.</b>
            </p>
        </Layout>
            
            <div className='row cardJustify'>
                <div className='cardMargin'>
                    <InfoCards
                        imgsrc={diatomite}
                        title="Diatomite"
                        text="Diatomaceous Earth"
                        heading="Name"
                        para="Derived from the remains of microscopic fossilized sea or freshwater algaes, diatomite is a naturally occurring, 
                        versatile mineral used in an array of applications from cosmetics to filtration."
                    />
                </div>
                <div className='cardMargin'>
                    <InfoCards
                        imgsrc={bentonite}
                        title="Bentonite"
                        text="Aluminum Phyllosilicate Clay Composed Of Montmorillonite"
                        heading="Name"
                        para="Bentonite is a highly absorbent, viscous plastic clay which is a valuable binding, sealing, absorbing and lubricating agent
                         in a huge variety of industries and applications."
                    />
                </div>
                <div className='cardMargin'>
                    <InfoCards
                        imgsrc={perlite}
                        title="Perlite"
                        text="Non-Crystalline Glass"
                        heading="Name"
                        para="Derived from volcanic rock, perlite is a natural, lightweight, inert and fireproof rock making it a rock of choice for 
                        a wide variety of end uses from cosmetics to construction."
                    />
                </div>
            </div>
            <div className='row cardJustify'>
                <div className='cardMargin'>
                    <InfoCards
                        imgsrc={kaolin}
                        title="Kaolin"
                        text="Silicate Clay Mineral"
                        heading="Name"
                        para="Kaolin is a rock from which the clay mineral kaolinite is derived. This one has been used for millennia as the principal ingredient in porcelain tableware. 
                        Today Imerys kaolins bring critical properties to a host of end applications ranging from paper to paints, fiberglass to cosmetics and pharmaceuticals."
                    />
                </div>
                <div className='cardMargin'>
                    <InfoCards
                        imgsrc={quartz}
                        title="Quartz"
                        text="Silicon Dioxide"
                        heading="Name"
                        para="Quartz is one of the most common and well-known minerals on the planet. 
                        Best known as the principal constituent of glass, quartz offers multiple other uses ranging from construction granulates to solar panels."
                    />
                </div>
                <div className='cardMargin'>
                    <InfoCards
                        imgsrc={talc}
                        title="Talc"
                        text="Clay Mineral Composed Of Magnesium Silicate"
                        heading="Name"
                        para="The softest rock on the planet, talc is an ultra-versatile mineral valued for its diverse properties by a wide range of applications."
                    />
                </div>
            </div>
            <div className='row cardJustify'>
                <div className='cardMargin'>
                    <InfoCards
                        imgsrc={moler}
                        title="Moler"
                        text="Montmorillonite Clay & Diatomite"
                        heading="Name"
                        para="Moler is a unique mixture of diatom shells and 
                        smectite clay used as an absorption agent in an unlimited array of applications ranging from cat litter to soil amendment to steel making."
                    />
                </div>
                <div className='cardMargin'>
                    <InfoCards
                        imgsrc={feldspar}
                        title="Feldspar"
                        text="Tectosilicate"
                        heading="Name"
                        para="Feldspar is the most abundant group of minerals in the Earth’s crust. 
                        Their use in industrial applications is widespread. In fact, feldspar can be found in a great many objects we use on a daily basis!"
                    />
                </div>
                <div className='cardMargin'>
                    <InfoCards
                        imgsrc={mica}
                        title="Mica"
                        text="Phyllosilicate Of Aluminium & Potassium"
                        heading="Name"
                        para="Mica is an ultra-platy, chemically resistant, reflective, refractive, resilient and temperature stable mineral, highly prized in a range of applications."
                    />
                </div>
            </div>
            <div className='row cardJustify'>
                <div className='cardMargin'>
                    <InfoCards
                        imgsrc={calciumCarbonate}
                        title="Limestone"
                        text="Calcium Carbonate"
                        heading="Name"
                        para="Calcium carbonate is an essential and versatile mineral which finds its way into our daily life."
                    />
                </div>
                <div className='cardMargin'>
                    <InfoCards
                        imgsrc={carbonBlack}
                        title="Carbon Black"
                        text="Amorphous Para-Crystalline Carbon"
                        heading="Name"
                        para="Carbon black is an extremely versatile substance which is making an increasingly valuable contribution to the automotive industry. 
                        Imerys is the leading supplier of highly conductive carbon-based solutions for conductive carbon black used in lithium-ion batteries powering electric
                         vehicles and consumer electronics. It is also a valuable ingredient in polymer compounds used in electronic devices and the automotive industry."
                    />
                </div>
                <div className='cardMargin'>
                    <InfoCards
                        imgsrc={ballclay}
                        title="Ball Clay"
                        text="Silicate Clay Rock With Kaolinite As The Main Constituent"
                        heading="Name"
                        para="Ball clay is an extremely rare rock, only found at a handful of locations around the world. Ball clay is particularly valued as a base material in the manufacture of ceramics."
                    />
                </div>
            </div>
           
    </Styles>
</React.Fragment>
)