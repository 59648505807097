import React from 'react';
import styled from 'styled-components';
import { Layout } from '../../components/Layout';
import m_biome from '../../assets/presentation/m-biome.png';
import pests_many from '../../assets/presentation/pests_many.png';
import bee_friendly from '../../assets/presentation/bee_friendly.png';


const Styles = styled.div`
    .mycon {
        display: flex;
        flex-wrap: wrap;
    }
    .mybox {
        flex: 1;
        flex-basis: 33.33%;
        text-align: center;
        padding: 10px;
    }
    .plant_bg {
        // background-color: rgb(11,114,97);
        background-color: rgb(11,100,97);
        margin: 0px;
        padding: 0px;
    }
`;

export const Mbiome = () => (
        <React.Fragment>
            <Styles>
                <div className="mycon">
                    <div className="mybox">
                        <h1>M-Biome</h1>
                    </div>
                    <div className="mybox">
                        <p>This is a pest dehydrator</p>
                    </div>
                </div>
            <div className='plant_bg'>

                <div className='mycon'>
                <div className='mybox'>
                    <img src={m_biome}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>​Physical Mode of Action</h3>
                    <p style={{color: 'white'}}>
                    It is not ingested by pests or plants and dehydrates the pests when they come in contact with it.​
                    </p>
                </div>
                <div className='mybox'>
                <img src={pests_many}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>140 Pest types targeted</h3>
                    <p style={{color: 'white'}}>
                    A single solution for multiple pests and any crop.
                    </p>
                </div>
                <div className='mybox'>
                <img src={bee_friendly}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Bee-Friendly</h3>
                    <p style={{color: 'white'}}>
                    It's not toxic to bees and does not impact beneficial insect populations.
                    </p>
                </div>
            </div>
        </div>
            </Styles>
        </React.Fragment>
                        
    );
