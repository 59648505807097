import React from 'react';
import { Carousel } from 'react-bootstrap';
import firstSlide from "../assets/farming/farming1.png";
import secondSlide from "../assets/farming/farming2.png";
import thirdSlide from "../assets/farming/farming3.png";
import fourthSlide from "../assets/farming/farming4.png";
import styled from 'styled-components';

const Styles = styled.div`
    .imagestyle {
        width: 20%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .carousel {
  width:900px;
  height:500px;
  margin: auto;
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}

`;
export const SlideShow = () => (
    <Styles>
    <Carousel className="carousel">
        <Carousel.Item>
            <img 
                className="imagestyle"
                src={firstSlide}
                alt="farming"
 
            />
            <Carousel.Caption>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <img 
                className="imagestyle"
                src={secondSlide}
                alt="farming"
 
            />
            <Carousel.Caption>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <img 
                className="imagestyle"
                src={thirdSlide}
                alt="farming"
 
            />
            <Carousel.Caption>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <img 
                className="imagestyle"
                src={fourthSlide}
                alt="farming"
 
            />
            <Carousel.Caption>
            </Carousel.Caption>
        </Carousel.Item>
    </Carousel>
    </Styles>
)