import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { Layout } from '../components/Layout';
import { CommonHeader } from '../components/CommonHeader';
//import marketpics from '../assets/marketpics.png';
//import customers from '../assets/customers.png';
import styled from 'styled-components';
import eLogo from '../assets/emailLogo.png';
import tLogo from '../assets/telephoneLogo.png';
import invoice_header from '../assets/invoice_header.jpg';
import ReactToPrint from 'react-to-print';
import Papa from 'papaparse';
import itemList from '../assets/list.csv';
import Select from 'react-select';

const Styles = styled.div`

    .headerstyle {
        text-align: center;
        font-family: Neutraface Display, script;
        padding-bottom: 15px;
        padding-top: 30px;
        text-transform: uppercase;
    }

    .ludwig {
        font-size: 1.5em;
        width: 100%;
        font-family: Open Sans;
        font-style: bold;
        color: #555555;
        text-align: center;
    }

    .imagestyle {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 20px;
    }

`;

const Invoice = () => {
    const [showInvoice, setShowInvoice] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [invoiceNo, setInvoiceNo] = useState("");
    const [date, setDate] = useState("");
    const [desc, setDesc] = useState([]);
    const [sno, setSno] = useState([]);
    const [unit, setUnit] = useState([]);
    const [rate, setRate] = useState([]);
    const [total, setTotal] = useState([]);
    const [loopCount, setLoopCount] = useState(["1"]);
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [delivery, setDelivery] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [csvData, setCsvData] = useState("");
    const componentRef = useRef();
    fetch(itemList).then((r) => r.text()).then(text => {setCsvData(text);})
    var results = Papa.parse(csvData);
    var myline = results.data[0];
    const options = [];
    const snoSelector = [];
    if (myline){
        for (let i = 0; i < results.data.length; i++){
            options.push({value: results.data[i][1], label: results.data[i][1]});
            snoSelector.push({sno: results.data[i][0], item: results.data[i][1], price: results.data[i][5]});
        }
    }
    
    function renderDate(mydate){
        const myArray = mydate.split("-");
        const finalDate = myArray[2] + "/" + myArray[1] + "/" + myArray[0];
        return finalDate;
    }

    const removeItem = (index) => {
        const list_desc = [...desc];
        const list_sno = [...sno];
        const list_unit = [...unit];
        const list_rate = [...rate];
        const list_total = [...total];
        const list_loop = [...loopCount];
        list_desc.splice(index, 1);
        list_sno.splice(index, 1);
        list_unit.splice(index, 1);
        list_rate.splice(index, 1);
        list_total.splice(index, 1);
        list_loop.splice(index, 1);
        setDesc(list_desc);
        setSno(list_sno);
        setUnit(list_unit);
        setRate(list_rate);
        setTotal(list_total);
        setLoopCount(list_loop);
        let sub_total = 0;
        for (let i = 0; i < list_unit.length; i++){
            sub_total = sub_total + list_unit[i] * list_rate[i];
        }
        setSubTotal(sub_total);
        setGrandTotal(sub_total * (1 - discount/100) + delivery*1);
    }
    const handleDesc = (e, index) => {
        const list = [...desc];
        list[index] = e.label;
        setDesc(list);
        for (let i = 0; i < snoSelector.length; i++){
            if (snoSelector[i].item === e.label){
                const sno_list = [...sno];
                sno_list[index] = snoSelector[i].sno;
                setSno(sno_list);
                const rate_list = [...rate];
                rate_list[index] = snoSelector[i].price;
                setRate(rate_list);
                const totalList = [...total];
                if (unit[index]){
                    totalList[index] = unit[index] * rate_list[index];
                    setTotal(totalList);
                }
                else {
                    totalList[index] = 0;
                    setTotal(totalList);
                }
                if (totalList.length){
                    let sum = 0;
                    for (let i = 0; i < totalList.length; i++){
                        sum = sum + totalList[i];
                    }
                    setSubTotal(sum);
                    setGrandTotal(sum * (1 - discount/100) + delivery*1);
                }
            }
        }
    }
    const handleSno = (e, index) => {
        const list = [...sno];
        list[index] = e.target.value;
        setSno(list);
    }
    const handleUnit = (e, index) => {
        const list = [...unit];//TODO: Add logic for calculating amount automatically
        list[index] = e.target.value;
        setUnit(list);
        const totalList = [...total];
        
        if (rate[index]){
            totalList[index] = list[index] * rate[index];
            setTotal(totalList);
        }
        else {
            totalList[index] = 0;
            setTotal(totalList);
        }
        if (totalList.length){
            let sum = 0;
            for (let i = 0; i < totalList.length; i++){
                sum = sum + totalList[i];
            }
            setSubTotal(sum);
            setGrandTotal(sum * (1 - discount/100) + delivery*1);
        }
    }

    const handleRate = (e, index) => {
        const list = [...rate];
        list[index] = e.target.value;
        setRate(list);
        const totalList = [...total];
        if (unit[index]){
            totalList[index] = unit[index] * list[index];
            setTotal(totalList);
        }
        else {
            totalList[index] = 0;
            setTotal(totalList);
        }
        if (totalList.length){
            let sum = 0;
            for (let i = 0; i < totalList.length; i++){
                sum = sum + totalList[i];
            }
            setSubTotal(sum);
            setGrandTotal(sum * (1 - discount/100) + delivery*1);
        }
    }
    
    const handleDiscount = (e) => {
        setDiscount(e.target.value);
        setGrandTotal(subTotal * (1 - e.target.value/100) + delivery*1);
    }

    const handleDelivery = (e) => {
        setDelivery(e.target.value);
        setGrandTotal(subTotal * (1 - discount/100) + e.target.value*1);
    }

    const handleGrandTotal = (e) => {
        let newGrandTotal = e.target.value
        setGrandTotal(newGrandTotal);
        newGrandTotal = newGrandTotal - delivery*1;
        let perc = 100 - newGrandTotal/subTotal*100;
        perc = perc.toFixed(2);
        setDiscount(perc);
    }

    return (
    <React.Fragment>
    <Styles>
        <Layout>
            {showInvoice ?
            <div className='rounded shadow mt-5' >
            <ReactToPrint trigger={() => <button className='bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 '>Print</button>} 
                content={() => componentRef.current}
            />
            <div ref={componentRef}>
                <img src={invoice_header} alt='invoice_header' className='flex flex-col items-center justify-center mb-5' />
                <section className='flex justify-between'>
                    <div>
                    {/* sm:text-xs lg:text-xl w-4/5 */}
                    <p className='underline text-2xl font-bold'>BILL TO</p>
                    <p className='text-2xl'>{name}</p>
                    <p className='text-2xl'>{address}</p>
                    <p className='text-2xl'>{phone}</p>
                    </div>
                    <div className='pt-5'>
                    <p className='text-2xl'><b>{(invoiceNo) ? 'Invoice No.:'+invoiceNo : ''}</b></p>
                    <p className='text-2xl'><b>Invoice Date: {renderDate(date)}</b></p>    
                    </div>
                </section>
                <table className='w-full pt-1'>
                        <tr className='bg-gray-100'>
                            <th className='text-xl text-center font-bold'>S.No.</th>
                            <th className='text-xl text-center font-bold'>Description</th>
                            <th className='text-xl text-center font-bold'>Unit</th>
                            <th className='text-xl text-center font-bold'>Rate</th>
                            <th className='text-xl text-center font-bold'>Total</th>
                        </tr>
                        {loopCount.map((loop, index) => (
                            <tr>
                                <td className='text-lg text-center font-semibold'>{sno[index]}</td>
                                <td className='text-lg text-center font-semibold'>{desc[index]}</td>
                                <td className='text-lg text-center font-semibold'>{unit[index]}</td>
                                <td className='text-lg text-center font-semibold'>{rate[index]}</td>
                                <td className='text-lg text-center font-semibold'>{total[index]}</td>
                            </tr>
                        ))}
                        <tr>
                            <td className='text-lg text-center font-semibold'></td>
                            <td className='text-lg text-center font-semibold'></td>
                            <td className='text-lg text-center font-semibold'></td>
                            <td className='text-lg text-center font-semibold'>Sub-Total</td>
                            <td className='text-lg text-center font-semibold'>{subTotal}</td>
                        </tr>
                        {(discount > 0) ? <tr>
                            <td className='text-lg text-center font-semibold'></td>
                            <td className='text-lg text-center font-semibold'></td>
                            <td className='text-lg text-center font-semibold'></td>
                            <td className='text-lg text-center font-semibold'>Discount</td>
                            <td className='text-lg text-center font-semibold'>{discount}%</td>
                        </tr>: ''}
                        
                        <tr>
                            <td className='text-lg text-center font-semibold'></td>
                            <td className='text-lg text-center font-semibold'></td>
                            <td className='text-lg text-center font-semibold'></td>
                            <td className='text-lg text-center font-semibold'>Delivery Fee</td>
                            <td className='text-lg text-center font-semibold'>{delivery}</td>
                        </tr>
                        <tr>
                            <td className='text-lg text-center font-semibold'></td>
                            <td className='text-lg text-center font-semibold'></td>
                            <td className='text-lg text-center font-semibold'></td>
                            <td className='text-lg text-center font-semibold'>Grand Total</td>
                            <td className='text-lg text-center font-semibold'>{grandTotal}</td>
                        </tr>
                </table>
                <h3 className='flex flex-col items-center justify-center mb-5 pt-5 underline'>No Returns & No Exchanges for these items</h3>
                <section className='flex flex-col items-center justify-center'>
                    <h5>Mahameru Innovations</h5>
                    <h5>(Computer generated invoice hence Digitally Signed)</h5>
                </section>
            </div>
                <button onClick={() => setShowInvoice(false)} 
                    className='bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500'>
                    Edit Information
                </button>

            </div> : (
                <div className='flex flex-col justify-center'>
                    <label htmlFor='name'>Customer Name: </label>
                    <input type='text' id='name' placeholder='Enter Name' className='shadow' value={name} onChange={(e) => setName(e.target.value)} />
                    
                    <label htmlFor='address'>Customer Address: </label>
                    <textarea id='address' placeholder='Enter Address' className='shadow' value={address} onChange={(e) => setAddress(e.target.value)} />
                    
                    <label htmlFor='phone'>Customer Phone: </label>
                    <input type='text' id='phone' placeholder='Phone No.' className='shadow' value={phone} onChange={(e) => setPhone(e.target.value)} />
                    
                    <label htmlFor='invoiceNo'>Invoice No: </label>
                    <input type='text' id='invoiceNo' placeholder='Invoice No.' className='shadow' value={invoiceNo} onChange={(e) => setInvoiceNo(e.target.value)} />
                    
                    <label htmlFor='date'>Invoice Date: </label>
                    <input type='date' id='date' placeholder='Enter Date' className='shadow' value={date} onChange={(e) => setDate(e.target.value)} />
                    <div className='flex flex-col justify-center mt-2 p-2'>
                        {loopCount.map((loop, index) => (
                        <div key={index} className='flex flex-col justify-center mt-2 border-4 border-yellow-500 p-2'>
                            <label htmlFor={"desc"+index}>Item Description: </label>
                            {/* <input type='text' id={"desc"+index} placeholder='Item Description' className='shadow' value={desc[index]} onChange={(e) =>  handleDesc(e, index)} /> */}
                            <Select id={"desc"+index} options={options} onChange={(e) =>  handleDesc(e, index)} placeholder={desc[index]}/>
                            <label htmlFor={'sno'+index}>S.No.: </label>
                            <input type='text' id={'sno'+index} placeholder='S.No.' className='shadow' value={sno[index]} onChange={(e) => handleSno(e, index)} />
                            
                            <label htmlFor={'unit'+index}>Unit: </label>
                            <input type='text' id={'unit'+index} placeholder='Unit' className='shadow' value={unit[index]} onChange={(e) => handleUnit(e, index)} />
                            
                            <label htmlFor={'rate'+index}>Rate: </label>
                            <input type='text' id={'rate'+index} placeholder='Rate' className='shadow' value={rate[index]} onChange={(e) => handleRate(e, index)} />
                        
                            <label htmlFor={'total'+index}>Total: </label>
                            <input type='text' id={'total'+index} placeholder='Total' className='shadow' value={total[index]} />
                            <button onClick={() => removeItem(index)} 
                                className='bg-red-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-red-500 mt-5'>
                                Remove Item
                            </button>
                        </div>
                        ))}
                     <button onClick={() => setLoopCount([...loopCount, "2"])} 
                        className='bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 mt-5'>
                        Add Item
                    </button>
                    </div>
                    <label htmlFor='subTotal'>Sub-Total: </label>
                    <input type='text' id='subTotal' placeholder='Sub-Total' className='shadow' value={subTotal}/>

                    <label htmlFor='discount'>Discount(%): </label>
                    <input type='text' id='discount' placeholder='Discount' className='shadow' value={discount} onChange={(e) => handleDiscount(e)}/>
                    
                    <label htmlFor='delivery'>Delivery Fee: </label>
                    <input type='text' id='delivery' placeholder='Delivery Fee' className='shadow' value={delivery} onChange={(e) => handleDelivery(e)}/>

                    <label htmlFor='grandTotal'>Grand Total: </label>
                    <input type='text' id='grandTotal' placeholder='Grand Total' className='shadow' value={grandTotal} onChange={(e) => handleGrandTotal(e)}/>
                    
                    <button onClick={() => setShowInvoice(true)} 
                        className='bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 mt-5'>
                        Preview Invoice
                    </button>
                </div>
            )}
        </Layout>
    </Styles>
    </React.Fragment>
    );
}
export default Invoice;