import React from 'react';
import { CommonHeader } from '../components/CommonHeader';
import { Layout } from '../components/Layout';
import banner from '../assets/pdf/banner.png';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import mdefend from '../assets/pdf/m-defend.pdf';
import mgeoshield from '../assets/pdf/m-geoshield.pdf';
import mgeoshade from '../assets/pdf/m-geoshade.pdf';
import mterraboost from '../assets/pdf/m-terraboost.pdf';
import mhive from '../assets/pdf/m-hive.pdf';
import mvermipro from '../assets/pdf/m-vermipro.pdf';
import maquacare from '../assets/pdf/m-aquacare.pdf';
import brochure from '../assets/pdf/m-brochure.pdf';
import mterradry from '../assets/pdf/m-terradry.pdf';
import mperform from '../assets/pdf/m-perform.pdf';
import mdetox from '../assets/pdf/m-detox.pdf';

const Styles = styled.div`

    .imagestyle {
        width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media only screen and (min-width: 768px) {
        .imagestyle {
            width: 80%;
        }
    }

    .heading {
        font-family: 'Optima';
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 2.5em;
    }

    .ludwig {
        font-size: 1.5em;
        width:100%;
        font-family: 'Optima', Open Sans;
        font-weight: bold;
        color: #555555;
        padding-top: 0.5em;
        padding-right: 1em;
        padding-bottom: 1.2em;
        padding-left: 1em;
        line-height:1.6;
        position: relative;
        text-align: left;
    }
    @media only screen and (min-width: 768px) {
        .ludwig{
            padding-left: 10%;
        }
    }

    
    .social-icon {
        color: #b30000; /* Initial color */
        font-size: 24px;
        margin: 0 10px;
        transition: transform 0.3s ease, color 0.3s ease; /* Animation transition */
        text-decoration: none; /* Remove underline from links */
      }
      
      .social-icon:hover {
        color: #0077b6; /* Change color on hover */
        transform: scale(1.2); /* Scale icon on hover */
      }
      

`;


export const Catalog = () => (
    <React.Fragment>
        <CommonHeader 
            title="Our Solutions At A Glance"
            para="The Finest Products From The Land"
        />
        <Styles>
            <div>
                <h2 className='heading'>Our Solutions Catalogue</h2><hr />
                <p className='ludwig' style={{ paddingTop: '50px' }}>
                    <a href={brochure}>
                        <FontAwesomeIcon icon={faFilePdf} className='social-icon' />
                    </a>
                    <a href={brochure}>Download </a>our brochure to learn more about all our products&nbsp;
                </p>
            </div>
            <div>
                <h2 className='heading'>Plant Protection Solutions</h2><hr />
                <p className='ludwig' style={{ paddingTop: '50px' }}>
                    <a href={mdefend}>
                        <FontAwesomeIcon icon={faFilePdf} className='social-icon' />
                    </a>
                    <u><b><span style={{color: 'rgb(34, 54, 74)'}}><a href={mdefend}>M-Defend:</a></span></b></u> 
                    &nbsp;Natural Bee Friendly Pest Dehydrator for 140 Pest Types&nbsp;
                </p>
                <p className='ludwig'>
                    <a href={mgeoshield}>
                        <FontAwesomeIcon icon={faFilePdf} className='social-icon'/>
                    </a>
                    <u><b><span style={{color: 'rgb(34, 54, 74)'}}><a href={mgeoshield}>M-GeoShield:</a></span></b></u> 
                    &nbsp;Nature’s Shield against Pest Bites & Sun-‘Bites’&nbsp;
                </p>
                <p className='ludwig'>
                    <a href={mgeoshade}>
                        <FontAwesomeIcon icon={faFilePdf} className='social-icon'/>
                    </a>
                    <u><b><span style={{color: 'rgb(34, 54, 74)'}}><a href={mgeoshade}>M-GeoShade:</a></span></b></u> 
                    &nbsp;Natural protection from UV damage, for cooling surfaces, & camouflage from pests.&nbsp;
                </p>
            </div>
{/* ---------------------------------------------------------------------------------- */}
            <div>
                <h2 className='heading'>Soil Rejuvenation Solutions</h2><hr />
                <p className='ludwig' style={{ paddingTop: '50px' }}>
                    <a href={mterraboost}>
                        <FontAwesomeIcon icon={faFilePdf} className='social-icon' />
                    </a>
                    <u><b><span style={{color: 'rgb(34, 54, 74)'}}><a href={mterraboost}>M-TerraBoost:</a></span></b></u> 
                    &nbsp;Natural Soil Energiser, Silica Fertiliser & Nutrient Storehouse&nbsp;
                </p>
                <p className='ludwig'>
                    <a href={mhive}>
                        <FontAwesomeIcon icon={faFilePdf} className='social-icon'/>
                    </a>
                    <u><b><span style={{color: 'rgb(34, 54, 74)'}}><a href={mhive}>M-Hive:</a></span></b></u> 
                    &nbsp;Sustainable Honeycomb structured Soil Enhancer, Aerator, Protector.&nbsp;
                </p>
                <p className='ludwig'>
                    <a href={mvermipro}>
                        <FontAwesomeIcon icon={faFilePdf} className='social-icon'/>
                    </a>
                    <u><b><span style={{color: 'rgb(34, 54, 74)'}}><a href={mvermipro}>M-VermiPro:</a></span></b></u> 
                    &nbsp;A Natural, Sustainable Nutritional Powerhouse for the Plants and Soil&nbsp;
                </p>
            </div>
{/* ---------------------------------------------------------------------------------- */}
            <div>
                <h2 className='heading'>Animal Care & Feed Mill Solutions</h2><hr />
                <p className='ludwig' style={{ paddingTop: '50px' }}>
                    <a href={mterradry}>
                        <FontAwesomeIcon icon={faFilePdf} className='social-icon' />
                    </a>
                    <u><b><span style={{color: 'rgb(34, 54, 74)'}}><a href={mterradry}>M-TerraDry:</a></span></b></u> 
                    &nbsp;Sustainable Bedding Condiotioner for Stud, Bird, Diary & All other Animal Species&nbsp;
                </p>
                <p className='ludwig' style={{ paddingTop: '50px' }}>
                    <a href={mdetox}>
                        <FontAwesomeIcon icon={faFilePdf} className='social-icon' />
                    </a>
                    <u><b><span style={{color: 'rgb(34, 54, 74)'}}><a href={mdetox}>M-DeTox:</a></span></b></u> 
                    &nbsp;Natural Toxin-Binder & Anti-Caking agent for all Feed&nbsp;
                </p>
                <p className='ludwig' style={{ paddingTop: '50px' }}>
                    <a href={mperform}>
                        <FontAwesomeIcon icon={faFilePdf} className='social-icon' />
                    </a>
                    <u><b><span style={{color: 'rgb(34, 54, 74)'}}><a href={mperform}>M-Perform:</a></span></b></u> 
                    &nbsp;Feed Mill Additive for Anti-Caking, Lubrication & Reduced Power Consumption&nbsp;
                </p>
            </div>


            <div>
                <h2 className='heading'>Aquatic Ecosystem Solutions</h2><hr />
                <p className='ludwig' style={{ paddingTop: '50px' }}>
                    <a href={maquacare}>
                        <FontAwesomeIcon icon={faFilePdf} className='social-icon' />
                    </a>
                    <u><b><span style={{color: 'rgb(34, 54, 74)'}}><a href={maquacare}>M-AquaCare:</a></span></b></u> 
                    &nbsp;Sustainable, Natural, Antibiotic Free, Aquatic Ecosystem Preserver.&nbsp;
                </p>
            </div>
        </Styles>
    </React.Fragment>
);