import React from 'react';
import { Jumbotron as Jumbo, Container } from 'react-bootstrap';
import styled from 'styled-components';
import bgImage from '../assets/headerbg.jpg';
import bgImage_2 from '../assets/headerbg_2.jpg';
import mahameru3 from '../assets/mahameru3.jpg';

const Styles = styled.div`

    .jumbo {
        background: url(${bgImage_2}) no-repeat center center;
        ${'' /* background-size: cover; */}
        color: #efefef;
        height: 200px;
        position: relative;
        z-index: -2;
    }

    .overlay {
        background-color: #000;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
    .headerText{
        color: rgb(227,206,183);
        font-family: 'Optima', 'Julius Sans One', sans-serif;
        font-size: 1.3em;
    }
    .headerStyle {
        color: rgb(227,206,183);
        font-family: 'Optima', 'Julius Sans One', sans-serif;
        font-size: 1.8em;
        font-weight: bold;
    }
    @media (min-width: 769px) {
        .headerStyle {
            margin-left: -50px; /* Add this line to shift the h1 component to the left */
            font-size: 2.5em;
        }
        .headerText {
            margin-left: -50px; 
    }
    @media (max-width: 768px) {
        .headerStyle {
            font-size: 1.8em;  
        }
    }
    .imagestyle {
        width: 40%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }
//     .typewriter {
//     font-family: monospace;
//     overflow: hidden;
//     border-right: .15em solid orange;
//     white-space: nowrap;
//     margin: 0 auto;
//     letter-spacing: .15em;
//     animation: typing 3s steps(30, end), blink-caret .5s step-end infinite;
//   }
//   @keyframes typing {
//     from { width: 0 }
//     to { width: 100% }
//   }
//   @keyframes blink-caret {
//     from, to { border-color: transparent }
//     50% { border-color: orange; }
//   }
`;

export const CommonHeader = (props) => (
    <Styles>
        <Jumbo fluid className="jumbo">
            <div className="overlay"></div>
            <Container>
                <h1 className="headerStyle typewriter">{props.title}</h1>
                <p className="headerText typewriter">{props.para}</p>
            </Container>
        </Jumbo>
    </Styles>
)