import React from 'react';
import { Layout } from '../components/Layout';
import { CommonHeader } from '../components/CommonHeader';
import styled from 'styled-components';
import mahameru2 from '../assets/mahameru2.jpg';
import mahameru3 from '../assets/mahameru3.jpg';
import mahameru1_1 from '../assets/mahameru1_1.jpg';
import { Helmet } from 'react-helmet';
//TODO: make the colored section with no margins on the side, let the color be on the sides

const Styles = styled.div`

    .headerstyle {
        text-align: center;
        font-family: 'Optima', Neutraface Display, script;
        padding-top: 20px;
        text-transform: uppercase;
        font-size: 2.5em;    
    }

    .heading {
        font-family: 'Optima';
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 2.5em;
    }

    .ludwig {
        font-size: 1.5em;
        width:100%;
        font-family:'Berkshire Swash', cursive; /* TODO: Try different fonts */
        text-align: center;
        line-height:1.6;
        position: relative;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .imagestyle {
        width: 60%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media only screen and (min-width: 768px) {
        .imagestyle {
            width: 40%;
        }
    }
    .bgcolor {
        background-color: rgb(255,247,228);
        margin: 0px;
        padding: 0px;
    }
    .introstyle {
        font-size: 1.5em;
        width: 100%;
        font-family: 'Optima', Open Sans;
        font-style: bold;
        color: #555555;
        text-align: center;
        font-weight: bold;
    }
//     .typewriter {
//     font-family: monospace;
//     overflow: hidden;
//     border-right: .15em solid orange;
//     white-space: nowrap;
//     margin: 0 auto;
//     letter-spacing: .15em;
//     animation: typing 3s steps(30, end), blink-caret .5s step-end infinite;
//   }
//   @keyframes typing {
//     from { width: 0 }
//     to { width: 100% }
//   }
//   @keyframes blink-caret {
//     from, to { border-color: transparent }
//     50% { border-color: orange; }
//   }
`;

// module.exports = {
//     getArray: function() {
//         newUsers = ["array1", "array2"];
//         return newUsers;
//     }
// };

export const Home = () => (
    <React.Fragment>
    <Helmet>
        <title>Mahameru Innovations – Your source for Agrotech | Home</title>
        <meta name="description" content="We believe in a natural sustainable approach to nurturing the soil. 
        Our range of products are M-Hive, M-TerraBoost, M-TerraDry, M-Geoshade, M-Geoshield, M-Defend, M-VermiPro, M-Detox, M-Perform, M-Aquacare" />
    </Helmet>
    <Styles>
        <CommonHeader 
            title='"M-Power Nature, Blossom Life"'
        />
        <Layout>
            <div style={{marginTop: '20px'}}>
                <p className="introstyle">
                    Our vision is to make organic and nutritious food the new standard in all our homes, fostering ‘One Health’.
                    We innovate to bring positive disruption to the fundamentals of the food cycle. Our aim is to make all aspects Natural, Sustainable and in
                    Harmony with the native environment. This empowers the Soil & Soul.
                </p>
            </div>
            {/* change especially in the field of agriculture */}
            </Layout>

            <div className="bgcolor">
            <Layout>
                {/* TODO: Put this in the format of the animal section */}
                <h2 className="heading">The Quest</h2><hr></hr>
                <img 
                    src={mahameru1_1}
                    alt="mahameru inside"
                    className="imagestyle"
                />
                
                <p className="ludwig">Mahameru The Great Peak be it Literal or Transcendental atop 9 Triangles signifying the flow of energy within and without.</p>
                <img 
                    src={mahameru2}
                    alt="mahameru outside"
                    className="imagestyle"
                />
                <p className="ludwig">Surrounded by a Blooming Lotus and the 4 Gates that let the energy flow.</p>
                <img 
                    src={mahameru3}
                    alt="mahameru full"
                    className="imagestyle"
                />   
                <p className="ludwig">The Peak of Human Realisation is the Unlocking of an Infinite Potential towards a Conscious journey of Growth.</p>
                
                </Layout>
                </div>
        </Styles>
    </React.Fragment>
)

