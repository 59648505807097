import React from 'react';
import { CommonHeader } from '../components/CommonHeader';
import banner from '../assets/pdf/banner.png';
import styled from 'styled-components';
import { Layout } from '../components/Layout';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import group_1 from '../assets/agrame/1_group.jpg';
import logo_2 from '../assets/agrame/2_logo.jpg';
import talking_3 from '../assets/agrame/3_talking.jpg';
import talking_4 from '../assets/agrame/4_talking.jpg';
import talking_5 from '../assets/agrame/5_talking.jpg';
import presentation_6 from '../assets/agrame/6_presentation.jpg';
import viv_1 from '../assets/vivmea/viv_1.jpg';
import viv_2 from '../assets/vivmea/viv_2.jpg';
import viv_3 from '../assets/vivmea/viv_3.jpg';

// import ImageGallery from 'react-image-gallery';
// import 'react-image-gallery/styles/css/image-gallery.css';

const Styles = styled.div`

    .imagestyle {
        width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media only screen and (min-width: 768px) {
        .imagestyle {
            width: 80%;
        }
    }

    .heading {
        font-family: 'Optima';
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 2.5em;
    }

    .ludwig {
        font-size: 1.5em;
        width:100%;
        font-family: 'Optima', Open Sans;
        font-weight: bold;
        color: #555555;
        padding-top: 0.5em;
        padding-right: 1em;
        padding-bottom: 1.2em;
        padding-left: 1em;
        line-height:1.6;
        position: relative;
        text-align: center;
    }
    .video-frame {
        width: 80%;
        height: 315px;
        display: block;
        margin: 0 auto;
    }

    /* Styles for screens with width 768px and below (typically mobile devices) */
    @media (max-width: 768px) {
        .video-frame {
            width: 90%;
            height: 250px;
        }
    }

    /* Styles for screens with width above 768px (typically laptops and desktops) */
    @media (min-width: 769px) {
        .video-frame {
            width: 60%;
            height: 500px;
        }
    }
    

`;


export const Events = () => {
    const images = [
        {
            original: group_1,
            thumbnail: group_1,
            description: 'Mahameru Innovations at AgraME'
        },
        {
            original: logo_2,
            thumbnail: logo_2,
        },
        {
            original: talking_3,
            thumbnail: talking_3,
        },
        {
            original: talking_4,
            thumbnail: talking_4,
        },
        {
            original: talking_5,
            thumbnail: talking_5,
        },
        {
            original: presentation_6,
            thumbnail: presentation_6,
            description: 'Description for Image 6'
        },
    ];
    return (
    <React.Fragment>
        <CommonHeader 
            title="Events Space"
            para="Our Impact In The World"
        />
        <Styles>
            <div>
                <h2 className='heading'>Mahameru Innovations at AgraME</h2><hr /><br /><br />
                {/* <Layout>
                <ImageGallery 
                    items={images} 
                    autoPlay={true}
                />
                </Layout> */}
                {/* <img src={banner} alt="Banner_2" className='imagestyle' /> */}
                <Layout>
                    <Carousel autoPlay={true} infiniteLoop={true}>
                        <div>
                            <img src={group_1} alt="Mahameru Group at AgraME" />
                        </div>
                        <div>
                            <img src={logo_2} alt="Mahameru Logo at AgraME" />
                        </div>
                        <div>
                            <img src={talking_3} alt="Talking & Educating" />
                        </div>
                        <div>
                            <img src={talking_4} alt="Talking & Educating" />
                        </div>
                        <div>
                            <img src={talking_5} alt="Talking & Educating" />
                        </div>
                        <div>
                            <img src={presentation_6} alt="Presenting Climate Smart Agriculture" />
                            {/* <p className="legend">Legend 3</p> */}
                        </div>
                    </Carousel>
                </Layout>
                <p className='ludwig'>Experience the insight of our Chief Craftsman, Abhinav Garg as he speaks about "The Future Of Climate Smart Agriculture"</p><hr /><br /><br />
                <iframe 
                    className="video-frame"
                    src="https://www.youtube.com/embed/So9J-R3qfXo?si=Rsdqx0pNOiICOj0S&rel=0" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen
                ></iframe>
                {/* <h2 className='heading'>Mahameru Innovations at VIV MEA & HAN MEA 2023</h2><hr /><br /><br />
                <Layout>
                    <Carousel autoPlay={true} infiniteLoop={true}>
                        <div>
                            <img src={viv_1} alt="VIV MEA" />
                        </div>
                        <div>
                            <img src={viv_2} alt="HAN MEA" />
                        </div>
                        <div>
                            <img src={viv_3} alt="Visiting Stalls" />
                        </div>
                    </Carousel>
                </Layout> */}
            </div>
        </Styles>
    </React.Fragment>
)};