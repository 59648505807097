import React from 'react';
import { Layout } from '../components/Layout';
//import { Card, CardGroup } from 'react-bootstrap';
import {Table} from 'react-bootstrap';
import { CommonHeader } from '../components/CommonHeader';
//import processFlowImage from '../assets/processflow.png';
import styled from 'styled-components';
import venn from '../assets/venndiagram3.png';
import farmbook from '../assets/farmbook.png';
import { SlideShow } from '../components/SlideShow';
import farm_spray from '../assets/farming/farm_spray.png';
import pest_immune from '../assets/farming/pest.png';
import bee_noharm from '../assets/farming/bee.png';
import pesticide from '../assets/farming/pesticide.png';
import shield_1 from '../assets/farming/shield_1.png';

import m_biome from '../assets/presentation/m-biome.png';
import pests_many from '../assets/presentation/pests_many.png';
import bee_friendly from '../assets/presentation/bee_friendly.png';
import leaf_eater from '../assets/presentation/leaf_eater.png';
import pest_life_cycle from '../assets/presentation/pest_life_cycle.png';
import plant_sun from '../assets/presentation/plant_sun.png';
import shade_apple_2 from '../assets/presentation/shade_apple_2.png';
import photo_boost from '../assets/presentation/photo_boost.png';
import soil_plant from '../assets/presentation/soil_plant.png';
import tree_roots from '../assets/presentation/tree_roots.png';
import roots_nutrient from '../assets/presentation/roots_nutrient.png';
import harvest from '../assets/presentation/harvest.png';
import nursery_1 from '../assets/presentation/nursery_1.png';
import nursery_2 from '../assets/presentation/nursery_2.png';
import strong_roots from '../assets/presentation/strong_roots.png';
import soil_profile from '../assets/presentation/soil_profile.png';
import honeycomb_sphere from '../assets/presentation/honeycomb.png';
import hive_roots from '../assets/presentation/hive_roots.png';
import store_water from '../assets/presentation/store_water.png';
import silica_fert from '../assets/presentation/silica_fert.png';
import leaf_structure from '../assets/presentation/leaf_structure.png';
import hive_balls from '../assets/presentation/hive_balls.png';
import vermi_nutrition from '../assets/presentation/vermipro/vermi_nutrition.png';
import vermi_soil_struct from '../assets/presentation/vermipro/vermi_soil_struct.png';
import vermi_environment from '../assets/presentation/vermipro/vermi_environment.png';
import vermi_yield from '../assets/presentation/vermipro/vermi_yield.png';
import vermi_nursery from '../assets/presentation/vermipro/vermi_nursery.png';
import vermi_growth from '../assets/presentation/vermipro/vermi_growth.png';
import vermi_immunity from '../assets/presentation/vermipro/vermi_immunity.png';

import icon_natural_product from '../assets/presentation/icons/natural_product.png';
import icon_eco_friendly from '../assets/presentation/icons/eco-friendly.png';
import icon_un_sdg from '../assets/presentation/icons/un_sdg.png';
import icon_un_fao from '../assets/presentation/icons/un_fao.png';
import icon_carbon_footprint from '../assets/presentation/icons/carbon_footprint.png';
import icon_all from '../assets/presentation/icons/icon_all.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import mdefend from '../assets/pdf/m-defend.pdf';
import { Helmet } from 'react-helmet';
//TODO: change phone numbers

const Styles = styled.div`

    .headerstyle {
        text-align: center;
        font-family: Neutraface Display,script;
        padding-top: 20px;
        text-transform: uppercase;    
        font-size: 2.5em;
    }

    .subheaderstyle {
        text-align: center;
        font-family: Neutraface Display,script;
        padding-top: 20px;
        font-size: 2.2em;
    }
    .tableheaderstyle {
        text-align: center;
        font-family: Neutraface Display,script;
        padding-top: 20px;
        font-size: 1.8em;
    }

    .ludwig {
        font-size: 1.5em;
        width:100%;
        font-family:Open Sans;
        font-style: bold;
        color: #555555;
        padding-top: 0.5em;
        padding-right: 1em;
        padding-bottom: 1.2em;
        padding-left: 1em;
        line-height:1.6;
        position: relative;
    }

    .processflow {
        width: 100%;
        padding-bottom: 30px;
    }

    .cimprogettilogo {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .shaftcol {
        float: left;
        width: 50%;
        padding: 20px 150px 0px 150px;
    }

    .shaftrow {
        content: "";
        clear: both;
        display: table;
    }

    .cardtext {
        color: black;
        font-size: 1.2em;
    }

    .imagestyle {
        width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        padding-bottom: 20px;
    }
    
    @media only screen and (min-width: 768px) {
        .imagestyle {
            width: 60%;
        }
        .ludwig {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .rowText-left{
        font-size: 1.8em;
        font-style: bold;
        color: black;
        font-family: Open Sans;
        text-align: center;
        vertical-align: middle;
        border-right: 1.2px solid black;
    }
    .rowText-right{
        font-size: 1.2em;
        font-style: bold;
        color: black;
        font-family: Open Sans;
    }
    .tableStyle {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        border: 3px solid black;
    }
    .rowFormat {
        border-bottom: 1.2px solid black;
    }
    .mycon {
        display: flex;
        flex-wrap: wrap;
    }
    .mybox {
        flex: 1;
        flex-basis: 33.33%;
        text-align: center;
        padding: 10px;
    }
    .plant_bg {
        // background-color: rgb(11,114,97);
        background-color: rgb(11,100,97);
        margin: 0px;
        padding: 0px;
    }
    .quote {
        font-style:italic;
        color: #666;
        font-size: 1.5em;
        padding: 10px 20px;
        border-left: 5px solid #ddd;
        margin: 20px 0;
        ${'' /* background-color: rgb(255,247,228); */}
    }
    .highlight {
        height: 12px;
        width: 80%;
        max-width: 90vw;
        line-height: 1.6;
        border-radius: 2px;
        background-color: #2e2459;
        // margin: 0 auto;
    }
    .highlight2 {
        height: 12px;
        width: 80%;
        max-width: 90vw;
        line-height: 1.6;
        border-radius: 2px;
        background-color: #a72145;
        // margin: 0 auto;
    }
    @media only screen and (min-width: 768px) {
        .highlight {
            width: 35%;
        }
        .highlight2 {
            width: 35%;
        }
    }
    .download-button {
        background-color: #007BFF;   /* Change to your desired color */
        border: none;
        color: white;
        padding: 10px 20px;  /* Adjust for your desired size */
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;  /* Adjust for your desired font size */
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 12px;  /* Rounded corners */
        transition-duration: 0.4s;  /* Optional: for hover effect */
    
        /* Optional: Add a hover effect */
        &:hover {
            background-color: #0056b3;  /* Slightly darker shade of blue on hover */
            color: white;
        }
    }
    
    
`;

export const Agriculture = () => (
    
    <React.Fragment>
        <Helmet>
            <title>Eco-friendly agricultural solutions at Mahameru</title>
            <meta name="description" content="Why are aggrotech solutions so important to organic food? Maha meru has eco-friendly solutions that support Climate-Smart agriculture"></meta>
        </Helmet>
    <CommonHeader 
        title="Agriculture"
        para="Reconnect To The Source Of Food - Nature"
    />
    <Styles>
        <Layout>
        {/* 
            <h1 className="headerstyle">Process Flow<hr /></h1>
            <p className="ludwig">Our Production Line Features a Fully Automated Process Flow from Limestone Delivery to End Product Packaging</p>
            <img 
                src={processFlowImage}
                className="processflow"
                alt="Process Flow"
            />
            */}
            {/* TODO: can try centering the below para  */}
            <p className="quote" style={{textAlign: 'center'}}>
                "When we try to reconnect by growing food ourselves and buy chemicals to feed the plants, our natural survival instincts kick in and we 
                realise the drastic nature of chemicals used. We will then collectively search for a better way and take responsibility to grow sustainably."
            </p>

            {/* <h1 className="headerstyle">Our Agricultural Solutions<hr /></h1> */}
            <div className='mycon' style={{paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='mybox'>
                <img src={icon_natural_product}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'center', fontSize: '1.75rem'}}>Natural Products</h3>
                    <p style={{textAlign: 'left'}}>All of our products are pure in nature without any chemical additives at any processing stage.</p>
                </div>
                <div className='mybox'>
                    <img src={icon_eco_friendly}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'center', color: 'black', fontSize: '1.75rem'}}>Eco-Friendly</h3>
                    <p style={{color: 'black',textAlign: 'left'}}>They are chemically inert and are not harmful to the Environment, Humans or Animals.</p>
                </div>
                <div className='mybox'>
                    <img src={icon_un_sdg}
                            width={250}
                            height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'center', color: 'black', fontSize: '1.75rem'}}>Commited to UN SDGs</h3>
                    <p style={{color: 'black',textAlign: 'left'}}>We are aligned with UN's Sustainable Development Goals: SDG 6, 11, 13, 15. 
                    We are focused on environmentally stable communities for enhanced quality of living.</p>
                </div>
            </div>
            <div className='mycon' style={{paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='mybox'>
                    <img src={icon_un_fao}
                            width={250}
                            height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'center', fontSize: '1.75rem'}}>Supporting Climate-Smart Agriculture (CSA)</h3>
                    <p style={{textAlign: 'left'}}>We take a unified approach to address climate challenges in relation to agriculture and food security.​</p>
                </div>
                <div className='mybox'>
                    <img src={icon_carbon_footprint}
                            width={250}
                            height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'center', color: 'black', fontSize: '1.75rem'}}>Dedicated to Reduction in Carbon Footprint</h3>
                    <p style={{color: 'black',textAlign: 'left'}}>Our solutions help significant reduction in the Carbon Footprint impact of urbanisation and development</p>
                </div>
                <div className='mybox'>
                    <img src={icon_all}
                            width={250}
                            height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'center', color: 'black', fontSize: '1.75rem'}}>Solutions that Align with your Needs</h3>
                    <p style={{color: 'black',textAlign: 'left'}}>Our support is not limited to supplying the products, we work on problems on the ground and leverage a wide base of research and knowledge from multiple fields
                     of Engineering, Agriculture, Biology and find the right approach as per local conditions to get results.</p>
                </div>
            </div>
            {/* <p className='ludwig'> */}
                {/* TODO: can add small logo image instead of bullet point */}
                {/* <ul>
                    <li>All the products are pure in nature without any chemical additives at any processing stage.</li>
                    <li>They are chemically inert and are not harmful to the Environment, Humans or Animals.</li>
                    <li>Our support is not limited to supplying the products, our technical team will work on the problems faced and find solutions as per local conditions
                    as well as work on the ground to get results.</li>

                </ul>
            </p> */}
            {/* <p className="ludwig">
            We offer support in terms of navigating the legal and administrative framework of the country to local and international companies that offer innovative farming products. Our goal is in line with that of GCC/UAE with the vision of “Food Tech Valley” to promote growing food locally using innovative technology.
            </p> */}
            </Layout>
            <div className='plant_bg'>
            <Layout>
            <h2 className="subheaderstyle" style={{color: 'white', fontWeight: 'bold', paddingBottom: '20px'}}>
                  Plant Protection Solution
            </h2><hr />
            <br></br><br></br>
            <h3 style={{textAlign: 'left', color: 'white', fontWeight: 'normal', fontSize: '1.9rem', paddingBottom: '15px', paddingTop: '20px'}}>
                M-Defend (Pest Dehydrator)
                </h3>
                <div className='highlight'></div>
                <br></br>
            <div className='mycon'>
                <div className='mybox'>
                    <img src={m_biome}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>​Physical Mode of Action</h3>
                    <p style={{color: 'white'}}>
                    It is not ingested by pests or plants and dehydrates the pests when they come in contact with it.​
                    </p>
                </div>
                <div className='mybox'>
                <img src={pests_many}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>140 Pest types targeted</h3>
                    <p style={{color: 'white'}}>
                    A single solution for multiple pests and any crop.
                    </p>
                </div>
                <div className='mybox'>
                <img src={bee_friendly}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Bee-Friendly</h3>
                    <p style={{color: 'white'}}>
                    It's not toxic to bees and does not impact beneficial insect populations.
                    </p>
                </div>
            </div>
            <h3 style={{textAlign: 'left', color: 'white', fontWeight: 'normal', paddingTop:'20px', fontSize: '1.9rem', paddingBottom: '15px'}}>
                M-GeoShield (Plant Protection)​</h3>
                <div className='highlight'></div>
                <br></br>
            <div className='mycon'>
                <div className='mybox'>
                    <img src={leaf_eater}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Anti-Insect/Borer/Leaf Eaters Shield</h3>
                    <p style={{color: 'white'}}>
                    It acts as a natural barrier preventing pests from feeding or boring into the crop.
                    </p>
                </div>
                <div className='mybox'>
                <img src={pest_life_cycle}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Pest Life-Cycle Disruption</h3>
                    <p style={{color: 'white'}}>
                    It prevents the female insects from laying eggs by shielding the egg-laying surfaces.​
                    </p>
                </div>
                <div className='mybox'>
                <img src={plant_sun}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Sun-Burn Protection</h3>
                    <p style={{color: 'white'}}>
                    The layer applied on the plant protects against sunburn and heat stress damage.​
                    </p>
                </div>
            </div>
            <h3 style={{textAlign: 'left', color: 'white', fontWeight: 'normal', paddingTop:'20px', fontSize: '1.9rem'}}>
                M-GeoShade (Plant Support)</h3>​
                <div className='highlight'></div>
                <br></br>
            <div className='mycon'>
                <div className='mybox'>
                    <img src={shade_apple_2}
                        width={250}
                        height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Surface Temperature Reduction</h3>
                    <p style={{color: 'white'}}>
                    It forms a layer on top of the leaves or the fruits/vegetables and reduces the surface temperature thus reducing Abiotic Stress.​
                    </p>
                </div>
                <div className='mybox'>
                <img src={photo_boost}
                   width={250}
                   height={240}
                    />
                    <br></br><br></br>
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Photosynthesis Boost</h3>
                    <p style={{color: 'white'}}>
                    Due to lowering of the heat stress, the stomatal opening of leaves remains open for longer and the photosynthetic process is greatly improved.​
                    </p>
                </div>
                <div className='mybox'>
                <img src={soil_plant}
                       width={250}
                       height={240}
                    />
                    <br></br><br></br>
                    {/* TODO: Change */}
                    <h3 style={{color: 'white', fontWeight: 'normal', textAlign: 'center', fontSize: '1.6rem'}}>Fungi & Pathogen Suppressor</h3>
                    <p style={{color: 'white'}}>
                    Creates conditions that are unsuitable for fungi/pathogen growth thus reducing Biotic Stress.​
                    </p>
                </div>
            </div>
            </Layout>
            </div>
            {/* <p className='ludwig'>
                
                    <ul>
                        <li>Our pest control solution involves a mechanical insecticide in powder form that provides a physical, non-chemical mode of action.<br></br>
                    Can be applied as a Foliar spray using standard crop spraying equipment.</li>
                    <li>Insect, pests and mites can not become immune to its mode of action.</li>
                    <li>It's not toxic to bees and does not impact beneficial insect populations.</li>
                    </ul>
                    <br></br>
                     
                <ul>
                    <li>Plant Shield:</li>
                    Our plant shield solution's mode of action creates a natural barrier that prevents the egg laying from female insects 
                    & also prevents the insects from feeding leading to insect suppression. <br></br>
                    This solution also has the capability to offer sunburn protection, reducing heat stress damage.<br></br>
                    Higher yields are achieved due to the plant protection from insects and due to sunburn protection.<br></br>
                </ul>
                <ul>
                    <li>Sunburn Protection:</li>
                    Our plant shade solution forms a layer on top of the leaves or the fruits/vegetables and reduces the surface temperature on them & prevents damages 
                    caused by excessive UV radiation. <br></br>
                    Does not interfere with the photosynthesis of the plant.<br></br>
                    It also lowers the Heat Stress and sulfur toxicity (burn) under high dosing.<br></br>
                </ul>
            </p> */}
            <div style={{backgroundColor: 'rgb(56, 46, 99)', margin: '0px', padding: '0px'}}>
                <Layout>
                    <h2 className="subheaderstyle" style={{color: 'white', fontWeight:'bold', paddingBottom: '20px'}}>Soil Enhancement Solutions</h2><hr />
                    <br></br><br></br>
                    <h3 style={{textAlign: 'left', color: 'white', fontWeight:'normal', paddingTop:'20px', fontSize: '1.9rem', paddingBottom: '15px'}}>
                        M-TerraBoost  (Soil Enhancer & Silica Fertiliser)​</h3>
                        <div className='highlight2'></div>
                    <br></br>
                    <div className='mycon'>
                        <div className='mybox'>
                            <img src={store_water}
                                width={250}
                                height={240}
                            />
                            <br></br><br></br>
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Storehouse of Water & Nutrients</h3>
                            <p style={{color: 'white'}}>
                            Reduces water & fertiliser requirement by upto 70% by sustainably boosting the soil retention capacity of 
                            water & nutrients as well as maintaining the re-absorption capacity over time.​<br></br>
                            </p>
                        </div>
                        <div className='mybox'>
                        <img src={roots_nutrient}
                                width={250}
                                height={240}
                            />
                            <br></br><br></br>
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Nutrient Uptake Boost</h3>
                            <p style={{color: 'white'}}>
                            The CEC (cation exchange capacity) of soil is greatly improved, ​allowing plants to increase uptake of 
                            nutrients thus improving the crop quality and mineral​ content of the crop.​
                            </p>
                        </div>
                        <div className='mybox'>
                        <img src={harvest}
                                width={250}
                                height={240}
                            />
                            <br></br><br></br>
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Yield improvement</h3>
                            <p style={{color: 'white'}}>
                            The fresh weight at harvest is increased by upto 40%.​
                            </p>
                        </div>
                        <div className='mybox'>
                        <img src={nursery_2}
                            width={250}
                            height={240}
                            /> 
                            <br></br><br></br>
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Nursery Support</h3>
                            <p style={{color: 'white'}}>
                            It is able to significantly boost the germination rate by providing consistent conditions in the substrate.​
                            The initial root growth of the seedling is much more vigorous, allowing the plant to take root much faster 
                            when transplanted thus improving the survival rates.
                            </p>
                        </div>
                        <div className='mybox'>
                        <img src={tree_roots}
                                width={250}
                                height={240}
                            />
                            <br></br><br></br>
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Root Structure and Strength</h3>
                            <p style={{color: 'white'}}>
                            The plant rooting becomes very strong and the structure of the roots becomes visibly distinct. 
                            The overall length is also significantly greater allowing for better nutrient assimilation and soil binding.​
                            </p>
                        </div>
                        <div className='mybox'>
                        <img src={leaf_structure}
                               width={250}
                               height={240}
                            />
                            <br></br><br></br>
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Silica Fertiliser</h3>
                            <p style={{color: 'white'}}>
                            It contains Plant Available Silica which is an important component of plant health as recognised by the Association of American Plant Food Controls Officials (AAPFCO).
                            </p>
                        </div>
                    </div>
                    <h3 style={{textAlign: 'left', color: 'white', paddingTop:'80px', fontWeight:'normal', fontSize: '1.9rem', paddingBottom: '15px'}}>
                        M-Hive  (Soil Enhancer)</h3>
                        <div className='highlight2'></div>
                        <br></br>
                    <div className='mycon'>
                        <div className='mybox'>
                            <img src={soil_profile}
                                width={250}
                                height={240}
                            />
                            <br></br><br></br>
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Good Soil Profile</h3>
                            <p style={{color: 'white'}}>
                            Our solution creates a good soil profile: approx. 25% water, 25% air and 50% solid particulate matter.
                            </p>
                        </div>
                        <div className='mybox'>
                        <img src={honeycomb_sphere}
                               width={250}
                               height={240}
                            />
                            <br></br><br></br>
                            {/* Change */}
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Honeycomb Structure</h3> 
                            <p style={{color: 'white'}}>
                            Round Honeycomb structure: Gives Excellent Oxygenation to Roots. Allows Good aerobic bacteria to grow and prevent Root Rot as well as root damage 
                            due to soil compaction.
                            </p>
                        </div>
                        <div className='mybox'>
                        <img src={hive_roots}
                                width={250}
                                height={240}
                            />
                            <br></br><br></br>
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Strong Roots</h3>
                            <p style={{color: 'white'}}>
                            Stabilizes the plant & soil even in strong wind conditions.
                            </p>
                        </div>
                        <div className='mybox'>
                        <img src={hive_balls}
                               width={250}
                               height={240}
                            />
                            <br></br><br></br>
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>One Product, Many Solutions</h3>
                            <p style={{color: 'white'}}>
                            M-Hive is used in Greenhouses, Hydroponics, Open Field, Vertical Farming, Organic Farming, Aquaponics, Roof Farming, Sports Field, Golf courses.
                            </p>
                        </div>
                    </div>
                    <h3 style={{textAlign: 'left', color: 'white', paddingTop:'80px', fontWeight:'normal', fontSize: '1.9rem', paddingBottom: '15px'}}>
                        M-VermiPro  (Soil Fertiliser)</h3>
                        <div className='highlight2'></div>
                        <br></br>
                    <div className='mycon'>
                        <div className='mybox'>
                            <img src={vermi_nutrition}
                                width={250}
                                height={240}
                            />
                            <br></br><br></br>
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Nutritional Powerhouse</h3>
                            <p style={{color: 'white'}}>
                            Worm castings contain higher percentages of both macro and micronutrients compared to other natural composts. 
                            NPK is also available in a plant available form which is released as the plant grows.
                            </p>
                        </div>
                        <div className='mybox'>
                        <img src={vermi_soil_struct}
                               width={250}
                               height={240}
                            />
                            <br></br><br></br>
                            {/* Change */}
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Soil Structure Enrichment</h3> 
                            <p style={{color: 'white'}}>
                            The structure & texture of the soil is greatly enhanced by increasing porosity, water retention and aeration. 
                            This promotes healthy root structure & allows for better nutrient assimilation. 
                            </p>
                        </div>
                        <div className='mybox'>
                        <img src={vermi_environment}
                                width={250}
                                height={240}
                            />
                            <br></br><br></br>
                            <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Environmental Benefits</h3>
                            <p style={{color: 'white'}}>
                                M-VermiPro diverts waste from landfills & reduces the need for chemical fertilisers & treatments, 
                                contributing to sustainable agricultural practices. This reduces the environmental impact of large-scale farming.
                            </p>
                        </div>
                        <div className='mybox'>
                            <img src={vermi_yield}
                                width={250}
                                height={240}
                                />
                                <br></br><br></br>
                                <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Yield Improvement</h3>
                                <p style={{color: 'white'}}>
                                With regular use of M-VermiPro, the soil becomes fertile with higher organic matter content. 
                                This significantly increases the year-on-year yield & quality of crop.
                                </p>
                        </div>
                        <div className='mybox'>
                            <img src={vermi_nursery}
                                width={250}
                                height={240}
                                />
                                <br></br><br></br>
                                <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Nursery Support</h3>
                                <p style={{color: 'white'}}>
                                    Germination to seedling stage can be completed purely in M-VermiPro as it naturally maintains the conditions 
                                    required for germination and then nourishes the seedling for a virulent growth stage.
                                </p>
                        </div>
                        <div className='mybox'>
                            <img src={vermi_growth}
                                width={250}
                                height={240}
                                />
                                <br></br><br></br>
                                <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Plant Prosperity</h3>
                                <p style={{color: 'white'}}>
                                It also adds various vitamins, enzymes & hormones like auxins & gibberellins which are the essential 
                                building blocks for promoting new growth.
                                </p>
                        </div>
                        <div className='mybox'>
                            <img src={vermi_immunity}
                                width={250}
                                height={240}
                                />
                                <br></br><br></br>
                                <h3 style={{color: 'white', fontWeight:'normal', textAlign: 'center', fontSize: '1.6rem'}}>Immunity Booster</h3>
                                <p style={{color: 'white'}}>
                                As the plant becomes stronger, it is better able to deal with biotic & abiotic stress. 
                                M-VermiPro also naturally suppresses various pathogens that stops diseases from taking hold.
                                </p>
                        </div>
                    </div>
                    
                </Layout>
            </div>
            
            <div>
                {/* TODO: Check Screen size and accordingly add margin to the left and right of the text */}
            <h2 className="headerstyle" style={{paddingTop:'50px'}}>The Future of Farming<hr /></h2> 
            {/* the underline underneath the heading is gone to the right */}
            <p className="ludwig">
                Our multi-talented team is pushing the envelope of the definition of Agriculture by fundamentally altering the approach to growing food.
                We are developing innovative solutions from the quantum processes the plant uses to the soil it grows in. AI development and Machine Learning will also be a key part in our future and for the future of our food security. 
                We are actively functioning across India and the Gulf Cooperation Council (GCC) nations.
            </p>
            <img 
                    src={venn}
                    alt="Venn Diagram"
                    className="imagestyle"
            />
            </div>
    </Styles>
</React.Fragment>
)