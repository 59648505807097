import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import 'mdbreact/dist/css/mdb.css';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Styles = styled.div`
.social-icon {
  color: rgb(232, 204, 177); /* Initial color */
  font-size: 24px;
  margin: 0 10px;
  transition: transform 0.3s ease, color 0.3s ease; /* Animation transition */
  text-decoration: none; /* Remove underline from links */
}

.social-icon:hover {
  color: #0077b6; /* Change color on hover */
  transform: scale(1.2); /* Scale icon on hover */
}

`;
export const FooterComponent = () => (


    <MDBFooter color="stylish-color-dark" className="page-footer font-small pt-4 mt-4">
          <MDBContainer fluid className="text-center text-md-left">
            <MDBRow>
              <MDBCol md="6">
                <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                  Mahameru Innovations LLC
                </h5>
                <Styles>
                <a href="https://instagram.com/mahameru.me?igshid=MzRlODBiNWFlZA==" className="social-icon">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                &nbsp;
                <a href="https://www.facebook.com/profile.php?id=61551504729640" className="social-icon">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                &nbsp;
                <a href="https://www.linkedin.com/company/100999015/admin/feed/posts/" className="social-icon">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                </Styles>
                <p>
                <br />
                  Get In Touch:<br />
                  {/* Phone: <b>+971553713342</b> <br /> */}
                  Email: <b>info@maha-meru.com</b>
                </p>
              </MDBCol>
              <hr className="clearfix w-100 d-md-none" />
              <MDBCol md="2">
                <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                  Discover
                </h5>
                <ul className="list-unstyled">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/agriculture">Agriculture</a>
                  </li>
                  <li>
                    <a href="/animal">Animal</a>
                  </li>
                  <li>
                    <a href="/catalog">Catalogue</a>
                  </li>
                </ul>
              </MDBCol>
              <hr className="clearfix w-100 d-md-none" />
              <MDBCol md="2">
                <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                  Find Out More
                </h5>
                <ul className="list-unstyled">
                <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/career">Careers</a>
                  </li>
                  <li>
                    <a href="/events">Events</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </MDBCol>
              <hr className="clearfix w-100 d-md-none" />
              
            </MDBRow>
          </MDBContainer>
          
          <div className="footer-copyright text-center py-3">
            <MDBContainer fluid>
              &copy; {new Date().getFullYear()} Mahameru Innovations LLC
            </MDBContainer>
          </div>
        </MDBFooter>
        
    )
    