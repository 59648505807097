import React from 'react';
import {InfoCards} from './InfoCards';
import { Layout } from '../components/Layout';
import styled from 'styled-components';
import abhicard from '../assets/abhinav.jpg';
import akshaycard from '../assets/akshay.jpg';
import sudhircard from '../assets/sudhirpic.png';
import richikcard from '../assets/richikcard.jpg';
import sagnikcard from '../assets/sagnik.jpg';
import vidulcard from '../assets/vidul.jpg';
import meenacard from '../assets/meenakshi.jpg';
import bhavikcard from '../assets/bhavik.jpg';
import nancycard from '../assets/nancy.jpeg';
import mishacard from '../assets/misha.jpeg';
import mayankcard from '../assets/mayank.jpg';
import { SlideShow2 } from '../components/SlideShow2';
import './cardui.css';

const Styles = styled.div`

    .bgcolor {
        background-color: rgb(255,247,228);
        margin-bottom: 50px;
        padding: 0px;
        margin-top: 50px;
    }

    .introstyle {
        font-size: 1.5em;
        width: 100%;
        font-family: Open Sans;
        font-style: bold;
        color: #555555;
        text-align: center;
    }

    .headerstyle {
        text-align: center;
        font-family: Neutraface Display,script;
        padding-top: 20px;
        text-transform: uppercase;    
        font-size: 2.5em;
    }

    .ludwig {
        font-size: 1.5em;
        width:100%;
        font-family:Open Sans;
        font-style: bold;
        color: #555555;
        padding:0.5em 30px 1.2em 75px;
        line-height:1.6;
        position: relative;
        text-align: center;
    }
    .cardMargin {
        margin-bottom: 80px;
        max-height: 394px;
    }
    .cardJustify {
        justify-content: space-evenly;
        padding: 15px 100px;
        width: 100%;
        margin: 0px;
    }

`;


export const InfoCardsUI = () => (
    <React.Fragment>
        <h1 className="headerstyle">Members Of The House<hr /></h1>
        <Styles>
        <Layout> 
        <p className="introstyle"> Our team comprises of an Immense Knowledge base and we aim to approach any
         problem with multiple perspectives in order to optimise the solution.
          We also have the ability to tackle problems from a vast array of fields and are currently researching and
           developing unique solutions for the Future of Farming.
        The members have been working together for several years, to bring the vision of Mahameru Innovations to life. We have accumulated a
        rich experience of agriculture in GCC/UAE, UK and India.
 </p>
        </Layout>
        <div className="bgcolor">
                <div className="row cardJustify">
                <div className="cardMargin">
                <InfoCards 
                    imgsrc={abhicard} 
                    title="Mr. Abhinav Garg"
                    text="Director & Business Strategy" 
                    location="UAE"
                    heading="Profile"
                    para="Graduated with a Masters in Mechanical Engineering from the University of Bristol, UK and started his career with Rolls Royce, UK as an Engineer and a Project Manager. Since then has delved into various start-ups including Uma Lime Industry which is successfully designed, constructed and managed with a production capacity of 500 Tons/day of Quicklime. Brings a broad range of experience to the table from having worked in multiple countries each with their own unique culture." 
                    />
                </div>
                    <div className="cardMargin">
                        <InfoCards 
                            imgsrc={mayankcard} 
                            title="Mr Mayank Mishra" 
                            text="Business Development Manager"
                            location="UAE"
                            heading="Profile"
                            para="With more than 10 years of experience in Events, Marketing & business conceptualisation across multiple countries, He has the ability to develop innovative ways to work with clients from around the world and truly be an asset to the team. He is an effective communicator and organiser with a keen eye for detail."
                        />
                    </div> 
                    
                    <div className="cardMargin">
                    <InfoCards 
                        imgsrc={akshaycard} 
                        title="Mr. Akshay Garg" 
                        text="Chief Technical Officer"
                        location="UAE"
                        heading="Profile"
                        para="A Masters in Computer Engineering paired with a Masters in Business Management from the University of Warwick, UK is a powerful combination which has allowed him to develop customised industrial solutions focussing on the bottlenecks and manpower intensive processes. A passion for Maths & it’s bond with Computer Science has led into a deep dive of AI, Machine learning and their evolution with a focus on the practical application of it all for the future."
                        />
                </div>
        </div>
        <br />
        <br />
        <br />
            <div className="row cardJustify">
            <div className="cardMargin">
                    <InfoCards 
                        imgsrc={nancycard} 
                        title="Ms Nancy Garg" 
                        text="Creative & Marketing Lead"
                        location="UAE"
                        heading="Profile"
                        para="With a Masters in Mathematics and Education, she has taught across multiple cultures and curriculums. As a teacher she has trained to be perceptive and analytical of Human response. Added to that her passion for creativity and a superb eye for Aesthetics has led her to constantly challenge herself and even launched her own range of themed decoratives designed to invoke a positive energy response."
                    />
                </div>
                
                <div className="cardMargin">
                    <InfoCards 
                        imgsrc={bhavikcard} 
                        title="Mr. Vidul Gupta" 
                        text="Head of Software Development"
                        location="India"
                        heading="Profile"
                        para="A Computer Scientist with an intuitive knack for programming languages coupled with a Masters in Computer Application has resulted with him working on cutting edge projects such as real time communication and tracking of Satellites and connected devices. He is a Full Stack Developer with a drive to be at the cutting edge of all software driven technologies."
                    />
                </div>
                <div className="cardMargin">
                        <InfoCards 
                            imgsrc={sudhircard} 
                            title="Mr. Sudhir Garg" 
                            text="Financial Director"
                            location="UAE"
                            heading="Profile"
                            para="A Chartered Accountant with immense experience of more than 30 years working in the field of Finance and Banking. He has worked with numerous Industries and electronics groups such as Schweppes, Fly, Jindal and brings a great width of experience and knowledge to the table. He has travelled far and wide and is an acute judge of Human Character."
                        />
                    </div>
            </div>
        <br />
        <br />
        <br />
            <div className="row cardJustify">
                <div className="cardMargin">
                    <InfoCards 
                        imgsrc={meenacard} 
                        title="Ms Meenakshi Garg" 
                        text="HR Lead"
                        location="UAE" 
                        heading="Profile"
                        para="For over 30 years she has had management and HR experience working in American Consulate Schools such as the 
                       Diplomatic school of the American Embassy. As an entrepreneur she had opened her own school with a unique 
                       curriculum based on her experience. Her experience across vastly different cultures has allowed her to nurture growth,
                        passion, creativity through a unique perspective in all her employees."
                    />
                </div>
                <div className="cardMargin">
                    <InfoCards 
                        imgsrc={richikcard} 
                        title="Dr. Richik Sengupta"
                        text="Head of Research"
                        location="India"
                        heading="Profile"
                        para="With a PhD in Mathematics and a thirst to dive down into the very fundamentals of the building blocks of our world, he has started a journey as a professor to inspire the brightest minds in Mathematics and is researching the field of Quantum Computing. The applications of the research are resounding through every aspect of our lives."
                        />
                </div>
                {/* <div className="cardMargin">
                    <InfoCards 
                        imgsrc={sagnikcard} 
                        title="Mr. Sagnik Bhattacharjee" 
                        text="Environmental Engineer"
                        location="Czech Republic"
                        heading="Profile"
                        para="With a Masters in Atmosphere, Water & Urban Environment engineering, he has made bold steps in the field of urban environment mapping and Micro-Climate using geospatial and remote sensing earth observation technology. Using Python Programming he has also built powerful models for predicting Urban climate vulnerabilities. The applications for such modelling techniques are limitless and is a strong part of modern day Agriculutre."
                    />
                </div> */}
            </div>
        <br />
        <br />
            <div className="row cardJustify">
                <div className="cardMargin">
                    <InfoCards 
                        imgsrc={bhavikcard} 
                        title="Mr. Tushar Jain" 
                        text="Farmer & Agro-Consultant"
                        location="India"
                        heading="Profile"
                        para="An MBA from Rochester Institute of Technology, NY,USA. He has had a successful career in the financial sector before realizing his true calling and diving into Agriculture. He successfully runs his own farm (Sital Agri Farms) in New Delhi, India and is actively working with various organisations in order to support the local farmers with innovative farming practices."
                    />
                </div>
                
                <div className="cardMargin">
                    <InfoCards 
                        imgsrc={bhavikcard} 
                        title="Mr. Bhavik Mithani" 
                        text="Software Architect & Systems Design"
                        location="United Kingdom"
                        heading="Profile"
                        para="Graduated with a Masters in Mechanical Engineering from the University of Bristol, UK. He used his superior analysis skills to start a career as a technology analyst and strategist for various Financial Institutions including Royal bank of Scotland and JP Morgan to name a few. A passion for programming languages as well as teaching the next generation to code is immensely valuable."
                    />
                </div>
            </div>
        </div>
        <Layout>
        {/* <h1 className="headerstyle">Our Team's Specialised Training<hr /></h1>
            <p className="ludwig">
                Our team is working towards gaining the experience and training required to accomplish our goals.
                We believe in a strong knowledge base to truly make a change. 
            </p> */}
            {/* <SlideShow2 /> */}
        </Layout>   
        </Styles>
    </React.Fragment>
)