import React from 'react';
import { Layout } from '../components/Layout';
import { CommonHeader } from '../components/CommonHeader';
//import marketpics from '../assets/marketpics.png';
//import customers from '../assets/customers.png';
import styled from 'styled-components';
import eLogo from '../assets/emailLogo.png';
import tLogo from '../assets/telephoneLogo.png';

const Styles = styled.div`

    .headerstyle {
        text-align: center;
        font-family: Neutraface Display, script;
        padding-bottom: 15px;
        padding-top: 30px;
        text-transform: uppercase;
    }

    .ludwig {
        font-size: 1.5em;
        width: 100%;
        font-family: Open Sans;
        font-style: bold;
        color: #555555;
        text-align: center;
    }

    .imagestyle {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 20px;
    }


`;

export const Career = () => (
    
    <React.Fragment>
    <CommonHeader 
        title="Working At Mahameru Innovations"
        para="Join Us For leading the world towards a better place"
    />
    <Styles>
        <Layout>
            <h2 className='headerstyle'>Internship Program<hr /></h2>
            <p className='ludwig'>
                If you are a student interested in working with a diverse team of professionals from all over the world and engage in 
                our various software and agricultural solutions we provide all over the world. 
            </p>

            <h2 className='headerstyle'>Working At Mahameru Innovations<hr /></h2>
            <p className='ludwig'>
            Our mission is towards the Enrichment & Empowerment of Human Life experience especially through accelerating the world’s 
            transition to sustainable farming. We have highly experienced innovators in their respective fields who are coming together to 
            make this future a reality. Our culture is ever growing, focused on the people and highly innovative. We are a company without
             borders in which everyone irrespective of race, gender, religion, background or location can thrive as a community.
            </p>

            <h2 className='headerstyle'>Connect With Us<hr /></h2>
            <p className='ludwig'>
                Feel free to reach us at:<br />
                {/* <img src={tLogo} alt="Telephone Logo" width="20px" height="20px"/> +971523946911 <br /> */}
                <img src={eLogo} alt="Email Logo" width="20px" height="20px" /> careers@maha-meru.com
            </p>
            
        {/*
            <img 
                src={marketpics}
                alt="Market"
                className='imagestyle'
            />
             
            <h1 className='headerstyle'>Customers<hr /></h1>
            <img 
                src={customers}
                alt="Customers"
                className='imagestyle'
            />
            */}
        </Layout>
        
    </Styles>
    </React.Fragment>
)